import { useEffect, useState } from 'react';
import { FunctionComponent } from 'react';
import './contact-form.scss';
import { produce } from 'immer';

export type Props = { source: string; textfieldLabel: string; textfieldPlaceholder: string };

const ContactForm: FunctionComponent<Props> = (props) => {
  const [caseCreated, setCaseCreated] = useState<boolean>(false);
  const [payloadData, setPayloadData] = useState<{
    accountName: string;
    firstName: string;
    lastName: string;
    addressCountry: string;
    emailAddress: string;
    description: string;
    user1: string;
    dataSource: string;
  }>({
    accountName: '',
    firstName: '',
    lastName: '',
    addressCountry: '',
    emailAddress: '',
    description: '',
    user1: '',
    dataSource: '',
  });

  // Define the API URL and the endpoint for creating a case
  const apiUrl = 'https://crm.2x4.de/api/v1/LeadCapture/5a41be4cea4f4b997b18bfe3dadbc62a';

  // Function to create a case
  // ----------------------------------------------------------------
  async function createCase(data: any) {
    // ----------------------------------------------------------------
    //console.log(data);

    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.status} ${response.statusText}`);
      } else {
        setCaseCreated(true);
      }

      //const responseData = await response.json();
      //console.log('Case created successfully:', responseData);
    } catch (error) {
      console.error('Error creating case:', error);
    }
  }

  useEffect(() => {
    setPayloadData(
      produce(payloadData, (update) => {
        update.dataSource = props.source;
      })
    );
  }, [payloadData, props.source]);

  // ----------------------------------------------------------------
  // ----------------------------------------------------------------
  // ----------------------------------------------------------------
  // ----------------------------------------------------------------
  // ----------------------------------------------------------------
  return (
    <div className="form">
      {!caseCreated && (
        <>
          <div className="form-line">
            <div className="form-label">Company</div>
            <div className="form-input">
              <input
                className="full-width"
                type="text"
                placeholder="Company Name"
                value={payloadData.accountName}
                onChange={(e) => {
                  setPayloadData(
                    produce(payloadData, (update) => {
                      update.accountName = e.currentTarget.value;
                    })
                  );
                }}
              ></input>
            </div>
          </div>
          <div className="form-line">
            <div className="form-label">Your Name</div>
            <div className="form-input">
              <input
                className="half-width"
                type="text"
                placeholder="First Name"
                value={payloadData.firstName}
                onChange={(e) => {
                  setPayloadData(
                    produce(payloadData, (update) => {
                      update.firstName = e.currentTarget.value;
                    })
                  );
                }}
              ></input>
              <input
                className="half-width"
                type="text"
                placeholder="Last Name"
                value={payloadData.lastName}
                onChange={(e) => {
                  setPayloadData(
                    produce(payloadData, (update) => {
                      update.lastName = e.currentTarget.value;
                    })
                  );
                }}
              ></input>
            </div>
          </div>
          <div className="form-line">
            <div className="form-label">Country</div>
            <div className="form-input">
              <input
                className="full-width"
                type="text"
                placeholder="Country"
                value={payloadData.addressCountry}
                onChange={(e) => {
                  setPayloadData(
                    produce(payloadData, (update) => {
                      update.addressCountry = e.currentTarget.value;
                    })
                  );
                }}
              ></input>
            </div>
          </div>
          <div className="form-line">
            <div className="form-label">Email</div>
            <div className="form-input">
              <input
                className="full-width"
                type="text"
                placeholder="Your Company Email"
                value={payloadData.emailAddress}
                onChange={(e) => {
                  setPayloadData(
                    produce(payloadData, (update) => {
                      update.emailAddress = e.currentTarget.value;
                    })
                  );
                }}
              ></input>
            </div>
          </div>
          <div className="form-line">
            <div className="form-label">{props.textfieldLabel}</div>
            <div className="form-input">
              <textarea
                className="full-width"
                placeholder={props.textfieldPlaceholder}
                value={payloadData.description}
                onChange={(e) => {
                  setPayloadData(
                    produce(payloadData, (update) => {
                      update.description = e.currentTarget.value;
                    })
                  );
                }}
              />
            </div>
          </div>
          <div className="form-line">
            <div className="form-label"></div>
            <div className="form-input form-privacy">
              <input
                id="privacy"
                type="checkbox"
                checked={payloadData.user1 !== ''}
                onChange={(e) => {
                  setPayloadData(
                    produce(payloadData, (update) => {
                      update.user1 = e.currentTarget.checked
                        ? 'Policy Accepted: ' + new Date()
                        : '';
                    })
                  );
                }}
              ></input>
              <label htmlFor="privacy">
                I accept that the data above will be processed and stored according to the
                SurveyTester{' '}
                <a href="/privacy" target="_blank">
                  Privacy Policy
                </a>
              </label>
            </div>
          </div>
          <div className="form-line">
            <div className="form-label"></div>
            <div className="form-send">
              <input
                type="button"
                value="Send Message"
                onClick={() => {
                  createCase(payloadData);
                }}
                disabled={
                  !(
                    payloadData.accountName &&
                    payloadData.addressCountry &&
                    payloadData.description &&
                    payloadData.emailAddress &&
                    payloadData.firstName &&
                    payloadData.lastName &&
                    payloadData.user1
                  )
                }
              ></input>
            </div>
          </div>
        </>
      )}
      {
        // ===============================================================================
        caseCreated && (
          <div className="free-trial-confirmation">
            <div>
              <img src="/img/rocket.png" alt="Rocket" />
            </div>
            <div className="confirmation-text">
              <h4>Thanks for your Message</h4>
              <p>
                We are thrilled to get more information about your test project.
                <br />
                One of our QA experts will contact you shortly about the next steps to better Survey
                QA for you and your team.
              </p>
            </div>
          </div>
        )
      }
    </div>
  );
};

export default ContactForm;
