import { FunctionComponent } from 'react';
import Footer from '../shared/footer/footer';
import TopMenu from '../shared/top-menu/top-menu';

export type Props = {};

const Terms: FunctionComponent<Props> = (props) => {
  // ----------------------------------------------------------------
  // ----------------------------------------------------------------
  // ----------------------------------------------------------------
  // ----------------------------------------------------------------
  // ----------------------------------------------------------------
  return (
    <div className="page">
      <TopMenu selected="terms"></TopMenu>
      <div className="page-content bg-top">
        <div className="inner-content">
          <h1>SurveyTester Acceptable Use Policy</h1>
        </div>
      </div>

      <div className="page-content bg-1">
        <div className="inner-content">
          <h2>Legal Compliance</h2>
          <p>
            You must use our Services in compliance with applicable law. Using our Services
            alongside other tools for any unacceptable uses mentioned here is also prohibited.
          </p>

          <h2>Your Responsibilities</h2>
          <p>
            You are responsible for your projects, tests, and communications while using our
            Services. You must follow these requirements:
          </p>
          <ul>
            <li>
              Do not use the Services for unlawful activities or any activities where failure of the
              Services could lead to physical damage, death, mental harm, or personal injury.
            </li>
            <li>
              Do not use the Services with external survey links without the survey owner’s approval
              for automated testing.
            </li>
            <li>Do not provide access to the Services to anyone under the age of 13.</li>
            <li>
              Do not purchase, use, or access the Services for building a competitive product or
              service.
            </li>
            <li>
              Do not interfere with the normal operation of the Services or try to access them
              through unauthorized methods.
            </li>
            <li>
              Do not bypass any limitations imposed on your account (e.g., opening a new account to
              create or distribute a project closed for violation of terms or policies).
            </li>
            <li>
              Do not probe, scan, or test the vulnerability or security of the Services or any
              SurveyTester system without written authorization.
            </li>
            <li>
              Do not use automated systems to extract or scrape data from our interfaces without
              written authorization.
            </li>
            <li>
              Do not reverse engineer or deny others access to the Services, nor assist others in
              doing so.
            </li>
            <li>
              Do not store or transmit viruses, malware, or malicious software through the Services.
            </li>
            <li>Do not use the Services to infringe intellectual property rights.</li>
            <li>Do not resell or lease the Services without written authorization.</li>
            <li>
              If your use of the Services requires compliance with industry-specific regulations,
              you are solely responsible for such compliance unless agreed otherwise in writing with
              SurveyTester.
            </li>
            <li>
              Do not engage in abusive or excessive usage that affects the Services' speed,
              responsiveness, stability, availability, or functionality for other users.
              SurveyTester will notify you to reduce such usage if necessary.
            </li>
            <li>Do not register accounts using bots or other automated methods.</li>
          </ul>

          <h2>Phishing and Security</h2>
          <p>
            We protect user security and prevent fraudulent or malicious activities. We will suspend
            or remove any content that:
          </p>
          <ul>
            <li>
              Attempts to collect sensitive information like social security numbers, credit card
              numbers, or passwords.
            </li>
            <li>Publishes identifying information against a person's wishes.</li>
            <li>Deceives or misleads testers, including linking to malicious software.</li>
            <li>Creates answers for live surveys not in a test phase.</li>
            <li>Artificially boosts a website's search engine ranking.</li>
            <li>Hosts downloadable, live-streamed content or solicits clicks to other sites.</li>
          </ul>

          <h2>Privacy and Impersonation</h2>
          <p>
            Respect users' information by complying with all data protection laws and regulations
            regarding any data you submit or collect through our Services.
          </p>

          <h2>Email and Text Message Requirements</h2>
          <p>SurveyTester has a zero-tolerance spam policy:</p>
          <ul>
            <li>Use emails only for testing surveys with the service.</li>
            <li>
              Send emails and text messages only to people who have opted in or consented to receive
              communications from you.
            </li>
            <li>
              Do not send unsolicited emails to newsgroups, message boards, distribution lists, or
              email addresses.
            </li>
            <li>
              Ensure your emails do not generate excessive spam complaints or bounce rates. Emails
              that hard bounce will be blocked from further emails.
            </li>
          </ul>

          <h2>Reporting Spam</h2>
          <p>
            If you suspect spam use, contact us immediately at{' '}
            <a href="mailto:abuse@surveytester.com">abuse@surveytester.com</a>.
          </p>

          <h2>Violence and Hate Speech</h2>
          <p>
            We remove content and may report to law enforcement if there is a genuine risk of harm
            or threat to public safety:
          </p>
          <ul>
            <li>
              Do not use the Services to threaten or attack others, incite violence, harassment, or
              property damage.
            </li>
            <li>Do not use the Services for hate speech or to promote or fund such acts.</li>
            <li>Do not use the Services to glorify or promote self-harm.</li>
          </ul>

          <h2>Responsibility</h2>
          <p>SurveyTester is operated by:</p>
          <div className="contact-info">
            <p>
              2x4 Solutions GmbH
              <br />
              Fichtenring 8<br />
              84562 Mettenheim
              <br />
              Germany
              <br />
              <a href="http://www.2x4.de">www.2x4.de</a> –{' '}
              <a href="http://www.surveytester.com">www.surveytester.com</a>
              <br />
              <a href="mailto:info@2x4.de">info@2x4.de</a> –{' '}
              <a href="mailto:info@surveytester.com">info@surveytester.com</a>
            </p>

            <p>
              <strong>Managing Director:</strong>
              <br />
              Bernhard Witt
              <br />
              <a href="mailto:bwitt@2x4.de">bwitt@2x4.de</a>
              <br />
              Mobile: +49 174 1674678
            </p>

            <p>For any questions, feel free to contact us directly.</p>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default Terms;
