import React from 'react';
import './App.scss';
import { Route, Routes } from 'react-router-dom';
import Home from './home/home';
import About from './about/about';
import Contact from './contact/contact';
import Pricing from './pricing/pricing';
import Legal from './legal/legal';
import Privacy from './legal/privacy';
import Help from './help/help';
import Terms from './legal/terms';
import Trial from './14-day-trial/14-day-trial';
import HelpFAQ from './help/help-faq';
import HelpKB from './help/help-kb';
import HelpVideos from './help/help-videos';
import NewsletterConfirm1 from './shared/newsletter/newsletter-confirm-1';
import NewsletterConfirm2 from './shared/newsletter/newsletter-confirm-2';
import NewsletterUnsubscribe from './shared/newsletter/newsletter-unsubscribe';
import ScrollToTop from './scroll-to-top';
import PageNotFound from './PageNotFound/PageNotFound';

function App() {
  return (
    <div className="App light">
      <ScrollToTop /> {/* Add ScrollToTop here */}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="help" element={<Help />} />
        <Route path="help-faq" element={<HelpFAQ />} />
        <Route path="help-kb" element={<HelpKB />} />
        <Route path="help-videos" element={<HelpVideos />} />
        <Route path="pricing" element={<Pricing />} />
        <Route path="about" element={<About />} />
        <Route path="contact" element={<Contact />} />
        <Route path="freetrial" element={<Trial />} />
        <Route path="legal" element={<Legal />} />
        <Route path="privacy" element={<Privacy />} />
        <Route path="terms" element={<Terms />} />
        <Route path="newsletter-confirm-1" element={<NewsletterConfirm1 />} />
        <Route path="newsletter-confirm-2" element={<NewsletterConfirm2 />} />
        <Route path="newsletter-unsubscribe" element={<NewsletterUnsubscribe />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </div>
  );
}

export default App;
