import { FunctionComponent } from 'react';
import './newsletter.scss';
import Footer from '../footer/footer';
import TopMenu from '../top-menu/top-menu';

export type Props = {};

const NewsletterUnsubscribe: FunctionComponent<Props> = (props) => {
  // ----------------------------------------------------------------
  // ----------------------------------------------------------------
  // ----------------------------------------------------------------
  // ----------------------------------------------------------------
  // ----------------------------------------------------------------
  return (
    <div className="page contact">
      <TopMenu selected="contact"></TopMenu>
      <div className="page-content bg-top">
        <div className="inner-content">
          <h1>We're Sorry to See You Go! 😢</h1>
        </div>
      </div>
      <div className="page-content bg-1">
        <div className="inner-content">
          <h2>Hey,</h2>
          <p>
            It looks like you're considering saying goodbye to the SurveyTester newsletter, and that
            makes us a little sad. But we totally get it - your inbox is a busy place, and you need
            to keep it streamlined.
          </p>
          <p>
            <b>Before you go, here's a quick reminder of what you'll be missing out on:</b>
            <ul>
              <li>
                <b>Exclusive Updates:</b> Be the first to know about the latest testing strategies,
                tools, and trends that can save you time and resources.
              </li>
              <li>
                <b>Expert Tips:</b> Get insider knowledge from industry experts delivered straight
                to your inbox.
              </li>
              <li>
                <b>Productive Boost:</b> Discover how to streamline your testing processes, reduce
                errors, and enhance your productivity.
              </li>
            </ul>
          </p>
          <p>
            But hey, we respect your decision. If you need to part ways with us now, just know that
            the door is always open for your return. We'll miss sharing our latest and greatest with
            you, but we understand if you need a break.
          </p>
          <p>
            If you have any feedback or suggestions, we'd love to hear them. Your thoughts help us
            improve and better serve our community.
          </p>
          <p>
            <b>Want to stay connected?</b> You can always re-subscribe if you change your mind.
            We'll be here with open arms and fresh content whenever you're ready.
          </p>
          <br />
          <p>
            <b>Goodbye for now, but hopefully not forever,</b>
          </p>
          <p>The SurveyTester Team</p>
          <br />{' '}
          <p>
            P.S. If you're leaving because our content wasn't quite what you expected, or if there's
            something we could do better, we're all ears! Feel free to drop us a line at{' '}
            <a href="mailto:info@surveytester.com?subject=SurveyTester Newsletter Suggestions">
              info@surveytester.com
            </a>
            . We're always looking to improve.
          </p>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default NewsletterUnsubscribe;
