import { FunctionComponent } from 'react';
import './contact.scss';
import Footer from '../shared/footer/footer';
import TopMenu from '../shared/top-menu/top-menu';
import ContactForm from '../shared/contactform/contact-form';
import NewsletterBrevo from '../shared/newsletter/newsletter-brevo';

export type Props = {};

const Contact: FunctionComponent<Props> = (props) => {
  // ----------------------------------------------------------------
  // ----------------------------------------------------------------
  // ----------------------------------------------------------------
  // ----------------------------------------------------------------
  // ----------------------------------------------------------------
  return (
    <div className="page contact">
      <TopMenu selected="contact"></TopMenu>
      <div className="page-content bg-top">
        <div className="inner-content">
          <h1>Let's stay in touch</h1>
        </div>
      </div>
      <div className="page-content bg-1">
        <div className="inner-content">
          <p>
            You have more questions, any suggestions or just want to talk about testing surveys and
            the QA process? Then please fill out the form below, and send us you message.
          </p>
          <br />
          <ContactForm
            source="www.surveytester.com/contact"
            textfieldLabel="Message"
            textfieldPlaceholder="Your Message to us"
          />
        </div>
      </div>
      <div className="page-content bg-2">
        <div className="inner-content other-contact">
          <h2>Other ways to stay in touch</h2>
          <h3>LinkedIn</h3>
          <p>
            Follow us on LinkedIn to stay on top of the latest SurveyTester News. We regulary
            publish new stories and fascinating insights:
            <a
              href="https://www.linkedin.com/company/surveytester"
              rel="noreferrer"
              target="_blank"
            >
              {' '}
              SurveyTester@LinkedIn
            </a>
          </p>
          <h3>Our Newsletter</h3>
          <p>
            Register to our monthly Newsletter to get the latest insights about Survey QA methods,
            SurveyTester News, and much more. You can stop the newsletter at any time, if it does
            not suit your needs.
          </p>
          <div className="register-newsletter-line">
            <NewsletterBrevo />
          </div>
        </div>
      </div>
      <div className="page-content bg-1">
        <div className="inner-content"></div>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default Contact;
