import { FunctionComponent } from 'react';
import './newsletter.scss';
import Footer from '../footer/footer';
import TopMenu from '../top-menu/top-menu';

export type Props = {};

const NewsletterConfirm1: FunctionComponent<Props> = (props) => {
  // ----------------------------------------------------------------
  // ----------------------------------------------------------------
  // ----------------------------------------------------------------
  // ----------------------------------------------------------------
  // ----------------------------------------------------------------
  return (
    <div className="page contact">
      <TopMenu selected="contact"></TopMenu>
      <div className="page-content bg-top">
        <div className="inner-content">
          <h1>Thank you for Signing Up! 🎉</h1>
        </div>
      </div>
      <div className="page-content bg-1">
        <div className="inner-content">
          <h2>Hey there, Explorer! 🌟</h2>
          <p>
            We see you've got a curious mind—that's why you're here, right? At SurveyTester, we're
            all about empowering people like you to be ahead of the curve. By confirming your
            subscription, you're about to unlock a treasure trove of insights, tips, and exclusive
            content designed to make your testing process smoother, faster, and more efficient than
            ever before.
          </p>
          <p>
            But before you dive into the good stuff, there's just one last step:{' '}
            <b>Check your inbox.</b> We've sent you an email that needs your attention.
          </p>
          <p>
            <h3>Why Confirm?</h3>
            <ul>
              <li>
                <b>Exclusive Updates:</b> Be the first to know about the latest testing strategies,
                tools, and trends that can save you time and resources.
              </li>
              <li>
                <b>Expert Tips:</b> Get insider knowledge from industry experts delivered straight
                to your inbox.
              </li>
              <li>
                <b>Productive Boost:</b> Discover how to streamline your testing processes, reduce
                errors, and enhance your productivity.
              </li>
            </ul>
          </p>
          <p>Take a moment now to confirm your subscription. We promise it's worth it!</p>
          <p>
            In the meantime, imagine a future where your testing is seamless, your workflows are
            efficient, and your projects are always ahead of schedule. That future is just one click
            away.
          </p>
          <p>Ready? Go check that email and let's get started!</p>
          <br />
          <p>
            <b>See you soon in your inbox,</b>
          </p>
          <p>The SurveyTester Team</p>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default NewsletterConfirm1;
