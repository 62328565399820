import { FunctionComponent } from 'react';
import Footer from '../shared/footer/footer';
import TopMenu from '../shared/top-menu/top-menu';

export type Props = {};

const Legal: FunctionComponent<Props> = (props) => {
  // ----------------------------------------------------------------
  // ----------------------------------------------------------------
  // ----------------------------------------------------------------
  // ----------------------------------------------------------------
  // ----------------------------------------------------------------
  return (
    <div className="page">
      <TopMenu selected="legal"></TopMenu>
      <div className="page-content bg-top">
        <div className="inner-content">
          <h1>Legal Notice</h1>
        </div>
      </div>

      <div className="page-content bg-1">
        <div className="inner-content">
          <p>
            2x4 Solutions GmbH
            <br />
            Fichtenring 8<br />
            84562 Mettenheim
          </p>

          <p>
            Commercial Register: HRB 22887
            <br />
            Registration court: Traunstein
          </p>

          <p>
            <strong>Represented by:</strong>
            <br />
            Bernhard Witt
          </p>

          <h2>Contact</h2>
          <p>
            Phone: +49 8631 3516525
            <br />
            E-mail: info@surveytester.com
          </p>

          <h2>VAT ID</h2>
          <p>
            Sales tax identification number according to Sect. 27 a of the Sales Tax Law:
            <br />
            DE291029487
          </p>

          <h2>Information regarding professional liability insurance</h2>
          <p>
            <strong>Name and registered office of the insurer:</strong>
            <br />
            Hiscox Insurance Company Ltd.
            <br />
            Niederlassung f&uuml;r die Bundesrepublik Deutschland
            <br />
            Arnulfstr. 31
            <br />
            80636 M&uuml;nchen
          </p>
          <p>
            <strong>Scope of insurance:</strong>
            <br />
            Weltweit
          </p>

          <h2>Person responsible for editorial</h2>
          <p>
            Bernhard Witt
            <br />
            Fichtenring 8<br />
            84562 Mettenheim
          </p>

          <h2>EU dispute resolution</h2>
          <p>
            The European Commission provides a platform for online dispute resolution (ODR):{' '}
            <a href="https://ec.europa.eu/consumers/odr/" target="_blank" rel="noopener noreferrer">
              https://ec.europa.eu/consumers/odr/
            </a>
            .<br /> Our e-mail address can be found above in the site notice.
          </p>

          <h2>Dispute resolution proceedings in front of a consumer arbitration board</h2>
          <p>
            We are not willing or obliged to participate in dispute resolution proceedings in front
            of a consumer arbitration board.
          </p>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default Legal;
