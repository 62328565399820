import { FunctionComponent } from 'react';
import Footer from '../shared/footer/footer';
import TopMenu from '../shared/top-menu/top-menu';
import './PageNotFound.scss';

export type Props = {};

const PageNotFound: FunctionComponent<Props> = (props) => {
  // ----------------------------------------------------------------
  // ----------------------------------------------------------------
  // ----------------------------------------------------------------
  // ----------------------------------------------------------------
  // ----------------------------------------------------------------
  return (
    <div className="page page-not-found">
      <TopMenu selected="legal"></TopMenu>
      <div className="page-content bg-top">
        <div className="inner-content">
          <h1>404 Error: Page Not Found,</h1>
          But We Found Some Quirky Survey Data
        </div>
      </div>

      <div className="page-content bg-1">
        <div className="inner-content">
          <h2>It looks like the page you wanted took a survey and decided to go elsewhere.</h2>
          <b> But did you know 87% of people think 404 pages should be funnier?</b>
          <p>
            <img src="/img/404.webp" alt="404 error" />
          </p>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default PageNotFound;
