//import { useState, useEffect, useCallback, Fragment } from 'react';
import { FunctionComponent } from 'react';
//import { useNavigate } from 'react-router-dom';
import './footer.scss';

export type Props = {};

const Footer: FunctionComponent<Props> = (props) => {
  //const history = useNavigate();

  // ----------------------------------------------------------------
  // ----------------------------------------------------------------
  // ----------------------------------------------------------------
  // ----------------------------------------------------------------
  // ----------------------------------------------------------------
  return (
    <>
      <div className="footer">
        <div className="footer-details">
          <div className="address">
            <b className="column-header">Company</b>
            <br />
            SurveyTester
            <br />
            powered by 2x4 Solutions GmbH
            <br />
            Fichtenring 8 | 84562 Mettenheim | Germany
            <br />
            <a href="https://www.2x4.de" target="_blank" rel="noreferrer">
              www.2x4.de
            </a>
            {' | '}
            <a href="https://www.surveytester.com" target="_blank" rel="noreferrer">
              www.surveytester.com
            </a>
          </div>
          <div className="legal-links">
            <b className="column-header">Legal</b>
            <ul>
              <li>
                <a
                  href="/legal"
                  onClick={(e) => {
                    //e.preventDefault();
                    //history('/legal');
                  }}
                >
                  Site Notice
                </a>
              </li>
              <li>
                <a
                  href="/privacy"
                  onClick={(e) => {
                    //e.preventDefault();
                    //history('/privacy');
                  }}
                >
                  Privacy Policy
                </a>
              </li>
              <li>
                <a
                  href="/terms"
                  onClick={(e) => {
                    //e.preventDefault();
                    //history('/terms');
                  }}
                >
                  Terms of Use
                </a>
              </li>
              <li></li>
            </ul>
          </div>
          {/*
            
          <div className="segments-links">
            <b className="column-header">Segments</b>
            <ul>
              <li>
                <a
                  href="/about"
                  onClick={(e) => {
                    e.preventDefault();
                    history('/about');
                  }}
                >
                  Market Research
                </a>
              </li>
              <li>
                <a
                  href="/about"
                  onClick={(e) => {
                    e.preventDefault();
                    history('/about');
                  }}
                >
                  Healthcare Research
                </a>
              </li>
              <li>
                <a
                  href="/about"
                  onClick={(e) => {
                    e.preventDefault();
                    history('/about');
                  }}
                >
                  Social Research
                </a>
              </li>
              <li>
                <a
                  href="/about"
                  onClick={(e) => {
                    e.preventDefault();
                    history('/about');
                  }}
                >
                  Consumer Research
                </a>
              </li>
              <li>
                <a
                  href="/about"
                  onClick={(e) => {
                    e.preventDefault();
                    history('/about');
                  }}
                >
                  Financial Research
                </a>
              </li>
            </ul>
          </div>
          */}
          <div className="other-links">
            <b className="column-header">Product</b>
            <ul>
              <li>
                <a
                  href="/about"
                  onClick={(e) => {
                    //e.preventDefault();
                    //history('/about');
                  }}
                >
                  About
                </a>
              </li>
              <li>
                <a
                  href="/help"
                  onClick={(e) => {
                    //e.preventDefault();
                    //history('/help');
                  }}
                >
                  Help
                </a>
              </li>
              <li>
                <a
                  href="/pricing"
                  onClick={(e) => {
                    //e.preventDefault();
                    //history('/pricing');
                  }}
                >
                  Pricing
                </a>
              </li>
              <li>
                <a
                  href="/contact"
                  onClick={(e) => {
                    //e.preventDefault();
                    //history('/contact');
                  }}
                >
                  Contact
                </a>
              </li>
              <li>
                <a
                  href="/freetrial"
                  onClick={(e) => {
                    //e.preventDefault();
                    //history('/freetrial');
                  }}
                >
                  Free Trial
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="copyright-line">
        &copy; 2013-{new Date().getFullYear().toString()} -{' '}
        <a
          href="https://tsdr.uspto.gov/#caseNumber=90640714&caseType=SERIAL_NO&searchType=statusSearch"
          target="_blank"
          rel="noreferrer"
        >
          SurveyTester is a Registered Service Mark
        </a>
      </div>
    </>
  );
};

export default Footer;
