import { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';
import './top-menu.scss';
import SurveyTesterLogo from './../img/SurveyTester_Logo 2020_300x84.png';
export type Props = {
  selected:
    | 'home'
    | 'help'
    | 'pricing'
    | 'contact'
    | 'freetrial'
    | 'about'
    | 'terms'
    | 'legal'
    | 'privacy';
};

const TopMenu: FunctionComponent<Props> = (props) => {
  const history = useNavigate();

  // ----------------------------------------------------------------
  // ----------------------------------------------------------------
  // ----------------------------------------------------------------
  // ----------------------------------------------------------------
  // ----------------------------------------------------------------
  return (
    <div className="top-menu-wrapper">
      <div className="top-menu">
        <img src={SurveyTesterLogo} alt="SurveyTester"></img>
        <ul>
          <li
            className={props.selected === 'home' ? 'selected' : ''}
            onClick={(e) => {
              e.preventDefault();
              history('/');
            }}
          >
            <a href="/home">
              <span>Home</span>
            </a>
          </li>

          <li
            className={props.selected === 'pricing' ? 'selected' : ''}
            onClick={(e) => {
              e.preventDefault();
              history('/pricing');
            }}
          >
            <a href="/pricing">
              <span>Pricing</span>
            </a>
          </li>
          <li
            className={props.selected === 'about' ? 'selected' : ''}
            onClick={(e) => {
              e.preventDefault();
              history('/about');
            }}
          >
            <a href="/pricing">
              <span>About us</span>
            </a>
          </li>

          <li
            className={props.selected === 'contact' ? 'selected' : ''}
            onClick={(e) => {
              e.preventDefault();
              history('/contact');
            }}
          >
            <a href="/pricing">
              <span>Contact</span>
            </a>
          </li>
          <li
            className={props.selected === 'help' ? 'selected' : ''}
            onClick={(e) => {
              e.preventDefault();
              history('/help');
            }}
          >
            <a href="/help">
              <span>Help</span>
            </a>
          </li>

          <li
            className={props.selected === 'freetrial' ? 'selected' : ''}
            onClick={(e) => {
              e.preventDefault();
              history('/freetrial');
            }}
          >
            <a href="/freetrial">
              <span>Free Trial</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default TopMenu;
