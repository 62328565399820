import axios from 'axios';
import xml2js from 'xml2js';

export interface FeedItem {
  title: string;
  description: string;
  link: string;
  firstImage: string;
  pubDate: string;
  content: string;
  [key: string]: any;
}

export interface Feed {
  title: string;
  description: string;
  link: string;
  items: FeedItem[];
}

export async function fetchRSSFeed(url: string): Promise<Feed> {
  try {
    const response = await axios.get(url);
    const xml = response.data;
    const result = await xml2js.parseStringPromise(xml, { mergeAttrs: true });

    const channel = result.rss.channel[0];
    const structuredFeed: Feed = {
      title: channel.title[0],
      description: channel.description[0],
      link: channel.link[0],
      items: channel.item.map((item: any) => ({
        title: item.title[0],
        description: generateContentPreview(
          item['content:encoded'] ? item['content:encoded'][0] : item.description[0]
        )?.replace('http://', 'https://'),
        link: item.link[0],
        firstImage: getFirstImageUrl(
          item['content:encoded'] ? item['content:encoded'][0] : item.description[0]
        )?.replace('http://', 'https://'),
        pubDate: item.pubDate[0],
        content: (item['content:encoded']
          ? item['content:encoded'][0]
          : item.description[0]
        )?.replace('http://', 'https://'),
      })),
    };
    return structuredFeed;
  } catch (error) {
    console.error('Error fetching or parsing RSS feed:', error);
    throw error;
  }
}

/* Example:
const feedUrl = 'https://blog.surveytester.com/category/feed/FAQ';

fetchRSSFeed(feedUrl)
  .then((feed) => {
    console.log(JSON.stringify(feed, null, 2));
  })
  .catch((error) => {
    console.error('Error:', error);
  });
*/

function getFirstImageUrl(content: string): string | null {
  // Create a DOM parser
  const parser = new DOMParser();
  // Parse the HTML string into a document
  const doc = parser.parseFromString(content, 'text/html');
  // Find the first img element
  const imgElement = doc.querySelector('img');
  // Return the src attribute of the img element
  return imgElement ? imgElement.src : null;
}

function generateContentPreview(content: string, maxParagraphs: number = 5): string {
  // Create a DOM parser
  const parser = new DOMParser();
  // Parse the HTML string into a document
  const doc = parser.parseFromString(content, 'text/html');
  // Find all img elements and remove them
  const imgElements = doc.querySelectorAll('img');
  imgElements.forEach((img) => img.remove());
  // Find all <p> elements and filter out those starting with "Image:"
  const paragraphs = Array.from(doc.querySelectorAll('h2,p'))
    .filter((p) => !p.textContent?.trim().startsWith('Image:'))
    .slice(0, maxParagraphs + 1);
  // Extract and return the inner HTML of the selected paragraphs
  return paragraphs.map((p) => p.innerHTML).join('<br/>');
}
