import { FunctionComponent, useEffect, useState } from 'react';

import Footer from '../shared/footer/footer';
import TopMenu from '../shared/top-menu/top-menu';
import './help-faq.scss';
import { Feed, fetchRSSFeed } from './rss/rss';

export type Props = {};

const HelpFAQ: FunctionComponent<Props> = (props) => {
  const [faqFeed, setFaqFeed] = useState<Feed | undefined>(undefined);

  // ----------------------------------------------------------------
  useEffect(() => {
    // --------------------------------------------------------------
    const feedUrl = 'https://blog.surveytester.com/category/feed/FAQ';

    fetchRSSFeed(feedUrl)
      .then((feed) => {
        setFaqFeed(feed);
        console.log(feed);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }, []);

  // ----------------------------------------------------------------
  // ----------------------------------------------------------------
  // ----------------------------------------------------------------
  // ----------------------------------------------------------------
  // ----------------------------------------------------------------
  return (
    <div className="page help-faq">
      <TopMenu selected="help"></TopMenu>
      <div className="page-content bg-top">
        <div className="inner-content">
          <h1>SurveyTester FAQ</h1>
        </div>
      </div>
      <div className="page-content bg-1">
        <div className="inner-content">
          <h2>
            Learn best practices for SurveyTester and become an expert, equipped with the knowledge
            and skills for future success.
          </h2>

          <div className="faq-posts">
            {faqFeed?.items?.map((faqPost, i) => (
              <div key={i} className="faq-post">
                <img src={faqPost.firstImage} alt=""></img>
                <div>
                  <h3 className="post-title">{faqPost.title}</h3>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: faqPost.description,
                    }}
                  />
                  <div>
                    <a href={faqPost.link}>Read Complete Post</a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default HelpFAQ;
