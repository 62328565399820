//import { useState, useEffect } from 'react';
import { FunctionComponent } from 'react';
//import Rocket from './rocket.png';
import Footer from '../shared/footer/footer';
import TopMenu from '../shared/top-menu/top-menu';
import './home.scss';
//import { useNavigate } from 'react-router-dom';

export type Props = {};

const Home: FunctionComponent<Props> = (props) => {
  //const [objRocket, setObjRocket] = useState<HTMLImageElement | undefined>(undefined);
  //const history = useNavigate();

  //const [scrollPosition, setScrollPosition] = useState(0);

  /*
  useEffect(() => {
    setObjRocket(document.querySelector('#rocket') as HTMLImageElement);
    const startTop = objRocket?.getBoundingClientRect().top;
    const startHeight = objRocket?.getBoundingClientRect().height;
    const startWidth = objRocket?.getBoundingClientRect().width;
    const screenWidth = document.documentElement.getBoundingClientRect().width;

    const handleScroll = () => {
      const rect = objRocket?.getBoundingClientRect();
      if (startTop && startHeight && startWidth && rect) {
        const diff = (startTop - rect?.top) / 2;
        if (screenWidth > 600) {
          objRocket.style.height = startHeight - diff + 'px';
          objRocket.style.width = startWidth - diff + 'px';
          objRocket.style.paddingLeft = diff * 1.2 + 'px';
          objRocket.style.transform = 'rotate(' + -diff / 10 + 'deg)';
          objRocket.style.marginBottom = -diff + 'px';
        } else {
          objRocket.style.paddingLeft = diff * 1 + 'px';
          objRocket.style.height = startHeight - diff / 2 + 'px';
          objRocket.style.width = startWidth - diff / 2 + 'px';
          objRocket.style.transform = 'rotate(' + -diff / 10 + 'deg)';
        }
      }
    };

    if (objRocket) {
      document.querySelector('.App')?.addEventListener('scroll', handleScroll);
    }
    return () => {
      document.querySelector('.App')?.removeEventListener('scroll', handleScroll);
    };
  }, [objRocket]);
*/

  // ----------------------------------------------------------------
  // ----------------------------------------------------------------
  // ----------------------------------------------------------------
  // ----------------------------------------------------------------
  // ----------------------------------------------------------------
  return (
    <div className="page home">
      <TopMenu selected="home"></TopMenu>
      <div className="page-content bg-top">
        <div className="inner-content">
          <div className="headline-rocket">
            <div>
              <h1>Revolutionize Your Survey QA with SurveyTester</h1>
              <h2>Automate, Collaborate, and Elevate Your Survey Quality</h2>
            </div>
            {/* 
            <img
              id="___________rocket"
              src={Rocket}
              style={{
                // Original: 300px x 279px
                width: '150px',
                height: '140px',
                position: 'relative',
              }}
              alt=""
            />
            */}
          </div>

          <p>
            <b style={{ fontSize: '2rem' }}>Remember</b> the chaos of managing the QA process for
            hundreds or even thousands of surveys. It can feel like trying to navigate a storm
            without a compass. This is where SurveyTester comes in, transforming your QA chaos into
            a streamlined, efficient workflow.
          </p>
          <p>
            SurveyTester is not another survey tool; it's a revolutionary cloud-based platform
            designed to simplify and enhance your entire survey QA process.
          </p>
          <p>
            <b style={{ fontSize: '2rem' }}>Imagine</b> a system that integrates seamlessly with
            leading survey systems like ConfirmIt, Decipher, Qualtrics, Dimensions, and more.
          </p>
          <p>
            <b style={{ fontSize: '2rem' }}>Now, picture</b> your QA team saving hundreds of hours
            with the ability to check surveys faster, in more depth, and with a comprehensive
            overview of the QA process.
          </p>
        </div>
      </div>

      <div className="page-content bg-1">
        <div className="inner-content">
          <h2>Transform Your Survey QA Process</h2>
          <p>
            SurveyTester is the ultimate cloud solution for managing and optimizing your Survey
            Quality Assurance process. Discover how leading market research firms are achieving
            superior results with less budget and in shorter time using SurveyTester.
          </p>
          <p className="logo-list">
            <img src="/logos/burke.png" alt="Burke logo" />
            <img src="/logos/ipsos.png" alt="Ipsos logo" />
            <img src="/logos/interrogare.png" alt="Interrogare logo" className="smaller" />
            <img src="/logos/kantar.png" alt="Kantar logo" className="smaller" />
            <img src="/logos/crr.svg" alt="C+R Research logo" />
            <img src="/logos/yougov.png" alt="YouGov logo" className="smaller" />
            <img src="/logos/edgeresearch.jpg" alt="Edge Research logo" />
            <img src="/logos/kynetec.png" alt="Kynetec logo" />
          </p>
          <br />
          <h2>Trusted by Industry Leaders</h2>
          <p>
            Join the ranks of top MR firms that have revolutionized their Survey Quality Management
            with SurveyTester. While your team might be grappling with tight deadlines and budget
            constraints, these industry leaders are already reaping the benefits of optimized Survey
            QA.
          </p>
        </div>
      </div>

      <div className="page-content bg-2">
        <div className="inner-content">
          <h2>Key Benefits of SurveyTester</h2>
          <h3>Automation</h3>
          <p>
            SurveyTester streamlines the time-consuming aspects of the survey QA process and
            identifies technical issues such as missing images. While AI doesn't yet support full
            automation of the process, we are continuously integrating more AI features into our
            product.
          </p>
          <h3> Streamlined Manual Work</h3>
          <p>
            When manual intervention is necessary, SurveyTester provides a structured environment
            that minimizes your team's workload. Designed specifically for survey projects, it
            centralizes all information, keeping it secure and accessible.
          </p>
          <h3>Visual Insights</h3>
          <p>
            SurveyTester offers powerful visualization tools that highlight exactly where issues
            arise in your surveys. It presents a condensed view of survey functionality, allowing
            users to easily identify and address problems.
          </p>
          <h3>Continuous Team Improvement</h3>
          <p>
            By tracking a variety of data during project testing, SurveyTester helps your team
            identify strengths and weaknesses, facilitating continuous improvement over time.
          </p>
        </div>
      </div>

      <div className="page-content bg-1">
        <div className="inner-content">
          <h2>Addressing Industry Challenges</h2>
          <p>
            The MR industry faces numerous challenges, including fake respondents, click farms, and
            survey bots. SurveyTester not only identifies these issues but also enhances data
            quality through optimized survey processes. With the rise of generative AI, client
            expectations are higher than ever. SurveyTester meets these demands by automating
            problem detection and streamlining manual checks, ensuring seamless collaboration across
            your team.
          </p>
          <h2>Avoid Costly Mistakes</h2>
          <p>
            {' '}
            “We needed to repeat 500 interviews because of errors in the survey.” Calculate the cost
            of repeating 500 additional interviews and consider the potential damage to your
            reputation. Can you afford such a mistake even once a year?
          </p>
        </div>
      </div>

      <div className="page-content bg-2">
        <div className="inner-content">
          <h2>How It Works</h2>
          <div className="features">
            <div className="feature">
              <h3>Automated Testing</h3>
              <p>
                Our Survey Bots simulate user interactions to streamline survey testing. Unlike many
                survey systems that generate generic dummy data, SurveyTester Bots stand out by
                using the same User Interface as real respondents. As they navigate the survey,
                these bots gather detailed information and capture screenshots. This allows you to
                review survey routing, translations, and automatically detect technical issues such
                as missing images.
              </p>
            </div>
            <div className="feature">
              <h3>Mobile Testing</h3>
              <p>
                SurveyTester supports testing on mobile devices by automatically generating
                screenshots for iPhones and Android devices. Testers do not need to directly test
                surveys on these devices, as SurveyTester handles the screenshot creation, allowing
                testers to focus on other aspects of the QA process. Once the screenshots are
                generated, they can be easily reviewed, and any issues discovered can be promptly
                documented.
              </p>
            </div>
            <div className="feature">
              <h3>Team Collaboration</h3>
              <p>
                When testers identify issues in a survey, SurveyTester allows them to report errors
                directly within the survey system. They can classify errors and visually mark
                problem areas on the screen. Testers can also check if their teammates have already
                reported an error and view their testing history. All related information, including
                automatically generated screenshots, is stored in the database, giving survey
                authors comprehensive access to relevant details. Not only can they view issues and
                corresponding screenshots, but they can also see the exact steps the tester took to
                produce the error and replicate the test with precise reproduction of the test run.
              </p>
              <p>
                Connect Project Managers, Survey Authors, QA Professionals, Translators, External
                Link Checkers, and even your Clients into one cohesive system. This unified approach
                fosters collaboration, enhances communication, and ensures that everyone is on the
                same page, leading to more efficient project completion and higher-quality surveys.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="page-content bg-1">
        <div className="inner-content">
          <h2>Comprehensive Survey QA Solution</h2>
          <p>
            SurveyTester integrates seamlessly with most survey platforms, enhancing their
            capabilities. Whether your organization uses multiple survey systems or a single one,
            SurveyTester unifies your QA process to a common standard. It offers superior
            collaboration features and testing automation that simulates real respondents.
          </p>
          <p className="logo-list">
            <img src="/logos/askia.png" alt="Askia logo" className="smaller" />
            <img src="/logos/confirmit.png" alt="Forsta ConfirmIt logo" />
            <img src="/logos/decipher.png" alt="Forsta Decipher logo" className="smaller" />
            <img src="/logos/dimensions.jpg" alt="Unicom IBM SPSS Dimensions logo" />
            <img src="/logos/forsta.png" alt="Forsta logo" className="smaller" />
            <img src="/logos/intellisurvey.svg" alt="IntelliSurvey logo" className="smaller" />
            <img src="/logos/LimeSurvey.png" alt="LimeSurvey logo" />
            <img src="/logos/nfield.png" alt="nfield logo" />
            <img src="/logos/qualtrics.jpg" alt="Qualtrics logo" />
            <img src="/logos/sawtooth.png" alt="Sawtooth logo" />
            <img src="/logos/surveygizmo.png" alt="SurveyGizmo logo" className="smaller" />
            <img src="/logos/surveymonkey.jpg" alt="SurveyMonkey logo" />
            <img src="/logos/tivian.png" alt="Tivian logo" className="smaller" />
            <img src="/logos/voxco.jpg" alt="Voxco logo" />
          </p>
          <br />
          <p>
            Please let us know if you're interested in integrating any other survey systems. We are
            continually developing new plugins and would love to hear about the systems you need.
          </p>
        </div>
      </div>

      <div className="page-content bg-2">
        <div className="inner-content">
          <h2>Testimonials</h2>
          <div className="testimonial">
            <p>
              "SurveyTester has saved our company hundreds of hours in testing time. It has so many
              great tools and features to ensure our surveys are top quality and will work across
              all devices and browsers."
            </p>
            <p>
              <strong>Will Fender, Burke Inc.</strong>
            </p>
          </div>
          <div className="testimonial">
            <p>
              "In case I haven't made it clear to you already, on behalf of C+R, we can't thank you
              enough for all of the effort you've made to fine-tune SurveyTester for our usage.”
            </p>
            <p>
              <strong>Todd Eviston, C+R Research</strong>
            </p>
          </div>

          <div className="testimonial">
            <p>
              “One of the real advantages of SurveyTester is that you can reproduce your test, so if
              you have found a bug and you want to go straight there you just click or reuse the
              test run and you have the same answers. Before, you had to start from the beginning
              and you were never sure if you had picked the right answers. If you are testing a
              30-minute interview and the bug is near the end, then that can be 30 minutes each time
              you test it just to get to that point.”
            </p>
            <p>
              <strong>Rudi Hirmke, TNS Infratest (Now Kantar)</strong>
            </p>
          </div>
        </div>
      </div>

      <div className="page-content bg-1">
        <div className="inner-content">
          <h2>Pricing</h2>
          <p>
            Our pricing is straightforward and based solely on the number of projects you create.
            <br />
            There are no limits on the number of users or test runs required for your QA process
            within a project. <br />
            All users have access to the full range of premium features.
          </p>
          <div className="pricing">
            <div
              className="pricing-button"
              onClick={(e) => {
                //e.preventDefault();
                //history('/pricing');
              }}
            >
              <a href="/pricing">
                <span>View Pricing</span>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="page-content bg-2">
        <div className="inner-content">
          <h2>FAQ</h2>
          <div className="faq-item">
            <h3>What features are included in the free trial?</h3>
            <p>
              We believe every user should experience the full potential of SurveyTester, so we
              offer all premium features to all users during the trial period.
            </p>
          </div>
          <div className="faq-item">
            <h3>How does testing with SurveyBots work?</h3>
            <p>
              Our Survey Bots simulate respondents to thoroughly test your surveys. During testing,
              SurveyTester collects detailed information about each survey question, including the
              SurveyBot's answers, screenshots, and other relevant details.
            </p>
          </div>
          <div className="faq-item">
            <h3>What survey systems are supported?</h3>
            <p>
              We fully support a wide range of survey systems. Additionally, you can use features
              like reporting issues and detecting missing images even with unsupported systems.{' '}
              {
                // <a href="#supported-systems">Learn more</a>.
              }
            </p>
          </div>
        </div>
      </div>

      {/* 
      <div className="page-content bg-1">
        <div className="inner-content">
          <h2>Supported Systems</h2>
          <p>We integrate with the following survey systems:</p>
          <ul>
            <li>ConfirmIt</li>
            <li>Decipher</li>
            <li>Qualtrics</li>
            <li>Dimensions</li>
            <li>And more...</li>
          </ul>
        </div>
      </div>

      <div className="page-content bg-2">
        <div className="inner-content">
          <h2>About Us</h2>
          <p>
            Your mission is to streamline survey testing by integrating advanced automation and
            enabling effective collaboration. With SurveyTester, you lead the revolution in survey
            quality and efficiency.
          </p>
        </div>
      </div>
*/}
      <div className="page-content bg-1">
        <div className="inner-content">
          <h2>What's next?</h2>
          <p>
            Don't just take our word for it – experience the transformation yourself. Click the
            button below to start your free 14-day trial of SurveyTester, which includes
            complimentary training and much more. Alternatively, collaborate with one of our QA
            experts on your own survey project. Let us help you improve your surveys every day.
          </p>
          <div className="free-trial">
            <div
              className="free-trial-button"
              onClick={(e) => {
                //e.preventDefault();
                //history('/freetrial');
              }}
            >
              <a href="/freetrial">
                <span>Free Trial</span>
              </a>
            </div>
          </div>
        </div>
      </div>

      <Footer></Footer>
    </div>
  );
};

export default Home;
