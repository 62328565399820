import { FunctionComponent } from 'react';
import Footer from '../shared/footer/footer';
import TopMenu from '../shared/top-menu/top-menu';
import './help.scss';

export type Props = {};

const Help: FunctionComponent<Props> = (props) => {
  // ----------------------------------------------------------------
  // ----------------------------------------------------------------
  // ----------------------------------------------------------------
  // ----------------------------------------------------------------
  // ----------------------------------------------------------------
  return (
    <div className="page help">
      <TopMenu selected="help"></TopMenu>
      <div className="page-content bg-top">
        <div className="inner-content">
          <h1>Help is Just a Click Away!</h1>
        </div>
      </div>
      <div className="page-content bg-1">
        <div className="inner-content">
          <h2>
            Learn best practices for SurveyTester and become an expert, equipped with the knowledge
            and skills for future success.
          </h2>
          <p>
            We make starting with SurveyTester a breeze, requiring minimal changes to your current
            process. Get up and running within minutes, and continue working as you do now, while
            enjoying benefits like effortless issue reporting, tracking, and in-depth analysis of
            your survey QA. Plus, SurveyTester offers features beyond your imagination, making it
            essential to explore and learn the system thoroughly.
          </p>
          <br />
          <div className="help-areas">
            <a
              href="/help-kb"
              className="help-area"
              onClick={(e) => {
                //e.preventDefault();
                //history('/help-kb');
              }}
            >
              <img src="/img/kb.webp" alt="SurveyTester Knowledge Base" />
              <h2>Knowledge Base</h2>
            </a>

            <a
              href="/help-videos"
              className="help-area"
              onClick={(e) => {
                //e.preventDefault();
                //history('/help-videos');
              }}
            >
              <img src="/img/videos.webp" alt="SurveyTester Training Videos" />
              <h2>Training Videos</h2>
            </a>

            {/* 
            <div className="help-area">
              <img src={imgBlog} alt="SurveyTester Blog" />
              <h2>Blog</h2>
            </div>
            */}
            <a
              href="/help-faq"
              className="help-area"
              onClick={(e) => {
                //e.preventDefault();
                //history('/help-faq');
              }}
            >
              <img src="/img/faq.webp" alt="SurveyTester Faq" />
              <h2>FAQ</h2>
            </a>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default Help;
