import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop: React.FC = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    console.log('hhh');

    //document.documentElement.scrollTop = 0; // For most browsers
    //document.body.scrollTop = 0; // For Safari
    //window.scrollTo(0, 0);
    document.querySelector('.App')?.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

export default ScrollToTop;
