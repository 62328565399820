import { FunctionComponent, useEffect, useState } from 'react';

import Footer from '../shared/footer/footer';
import TopMenu from '../shared/top-menu/top-menu';
import './help-faq.scss';
import { Feed, fetchRSSFeed } from './rss/rss';

export type Props = {};

export interface VideoItem {
  title: string;
  description: string;
  videolink: string;
  imagelink: string;
  pdflink: string;
  pubDate: string;
  length: string;
  content: string;
}

const videolist: VideoItem[] = [
  {
    title: '#1 Your First Testing Session',
    description:
      'This video demonstrates how to use the pre-installed "Museums of New York" project in SurveyTester. It showcases how you can effectively leverage SurveyTester\'s features to track and manage survey issues.',
    videolink: 'https://download.surveytester.com/vs/sharing/OJABPtna#!aG9tZV92aWRlby02Mg==',
    imagelink: '',
    pdflink: '',
    pubDate: '2024-06-30',
    content: '',
    length: '7:43',
  },
  {
    title: '#2 Your First Project',
    description:
      "In this tutorial, you'll learn how to create your own project in SurveyTester. All you need is a survey link for testing purposes. You can use our demo survey link or one of your own.",
    videolink: 'https://download.surveytester.com/vs/sharing/OJABPtna#!aG9tZV92aWRlby02Ng==',
    imagelink: '',
    pdflink: '',
    pubDate: '2024-08-16',
    content: '',
    length: '5:52',
  },
];
const HelpVideos: FunctionComponent<Props> = (props) => {
  // ----------------------------------------------------------------
  // ----------------------------------------------------------------
  // ----------------------------------------------------------------
  // ----------------------------------------------------------------
  // ----------------------------------------------------------------
  return (
    <div className="page help-videos">
      <TopMenu selected="help"></TopMenu>
      <div className="page-content bg-top">
        <div className="inner-content">
          <h1>SurveyTester Videos</h1>
        </div>
      </div>
      <div className="page-content bg-1">
        <div className="inner-content">
          <h2>
            Learn best practices for SurveyTester and become an expert, equipped with the knowledge
            and skills for future success.
          </h2>

          <div className="videos">
            {videolist?.map((video, i) => (
              <div key={i} className="video">
                <img src={video.imagelink} alt=""></img>
                <div>
                  <h3 className="videotitle">{video.title}</h3>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: video.description,
                    }}
                  />
                  <div>
                    <a href={video.videolink} target="_blank" rel="noreferrer">
                      Show Video
                    </a>
                    <span style={{ fontSize: '0.8rem' }}>{' (' + video.length + ')'}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default HelpVideos;
