import { useState, useEffect, Fragment } from 'react';
import { FunctionComponent } from 'react';

import './calculator.scss';

export type Props = { Currency: string; costPerProject: string };

const Calculator: FunctionComponent<Props> = (props) => {
  const [numberOfProjectsPerMonth, setNumberOfProjectsPerMonth] = useState<number>(20);
  const [numberOfQuestionsPerProject, setNumberOfQuestionsPerProject] = useState<number>(35);
  const [numberOfLanguagesPerProject, setNumberOfLanguagesPerProject] = useState<number>(5);
  const [numberOfIssuesPerProject, setNumberOfIssuesPerProject] = useState<number>(50);
  const [numberOfReplaysPerProject, setNumberOfReplaysPerProject] = useState<number>(5);
  const [costPerHour, setCostPerHour] = useState<number>(50);
  const [calcScreenshots, setCalcScreenshots] = useState<boolean>(true);
  const [calcCheckExistingIssues, setCalcCheckExistingIssues] = useState<boolean>(true);
  const [calcReplayInterview, setCalcReplayInterview] = useState<boolean>(true);
  const [calcDummyData, setCalcDummyData] = useState<boolean>(true);
  const [calcTeamPerformance, setCalcTeamPerformance] = useState<boolean>(true);
  const [minutesScreenshots, setMinutesScreenshots] = useState<number>(0);
  const [minutesCheckExistingIssues, setMinutesCheckExistingIssues] = useState<number>(0);
  const [minutesReplayInterview, setMinutesReplayInterview] = useState<number>(0);
  const [minutesDummyData, setMinutesDummyData] = useState<number>(0);
  const [minutesTeamPerformance, setMinutesTeamPerformance] = useState<number>(0);
  const [hoursTotal, setHoursTotal] = useState<number>(0);

  useEffect(() => {
    let tempTotalMinutes = 0;

    // -------------------------------------------------------
    if (calcScreenshots === true) {
      const tempMinutesScreenshots = Math.round(
        (2 * (numberOfProjectsPerMonth * numberOfIssuesPerProject)) / 60
      );
      setMinutesScreenshots(tempMinutesScreenshots);
      tempTotalMinutes += tempMinutesScreenshots;
    } else {
      setMinutesScreenshots(0);
    }

    // -------------------------------------------------------
    if (calcCheckExistingIssues === true) {
      const tempMinutesCheckExistingIssues = Math.round(
        (1 * (numberOfProjectsPerMonth * numberOfIssuesPerProject)) / 60
      );
      setMinutesCheckExistingIssues(tempMinutesCheckExistingIssues);
      tempTotalMinutes += tempMinutesCheckExistingIssues;
    } else {
      setMinutesCheckExistingIssues(0);
    }

    // -------------------------------------------------------
    //Replay to 50% progressbar
    //30s per question
    if (calcReplayInterview === true) {
      const tempMinutesReplayInterview = Math.round(
        (0.5 *
          numberOfProjectsPerMonth *
          (numberOfQuestionsPerProject / 2) *
          numberOfReplaysPerProject) /
          60
      );
      setMinutesReplayInterview(tempMinutesReplayInterview);
      tempTotalMinutes += tempMinutesReplayInterview;
    } else {
      setMinutesReplayInterview(0);
    }

    // -------------------------------------------------------
    //15s per question
    //20 Interviews
    if (calcDummyData === true) {
      const tempMinutesDummyData = Math.round(
        (0.25 * 20 * (numberOfProjectsPerMonth * numberOfQuestionsPerProject)) / 60
      );
      setMinutesDummyData(tempMinutesDummyData);
      tempTotalMinutes += tempMinutesDummyData;
    } else {
      setMinutesDummyData(0);
    }

    // -------------------------------------------------------

    if (calcTeamPerformance === true) {
      const tempMinutesTeamPerformance = numberOfProjectsPerMonth;
      setMinutesTeamPerformance(tempMinutesTeamPerformance);
      tempTotalMinutes += tempMinutesTeamPerformance;
    } else {
      setMinutesTeamPerformance(0);
    }
    // -------------------------------------------------------

    setHoursTotal(tempTotalMinutes);
  }, [
    calcCheckExistingIssues,
    calcDummyData,
    calcReplayInterview,
    calcScreenshots,
    calcTeamPerformance,
    numberOfIssuesPerProject,
    numberOfProjectsPerMonth,
    numberOfQuestionsPerProject,
    numberOfReplaysPerProject,
  ]);

  // ----------------------------------------------------------------
  // ----------------------------------------------------------------
  // ----------------------------------------------------------------
  // ----------------------------------------------------------------
  // ----------------------------------------------------------------
  return (
    <>
      <h2>Are you curious how much time and money you can save when using SurveyTester?</h2>
      <p>Obviously, this question depends on some numbers. Let's try to get an estimate:</p>
      <div className="calculator">
        <table>
          <tbody>
            <tr>
              <td colSpan={2}>Number of projects per month</td>
              <td>
                <input
                  type="number"
                  value={numberOfProjectsPerMonth}
                  onChange={(e) => {
                    setNumberOfProjectsPerMonth(parseInt(e.target.value));
                  }}
                />
              </td>
              <td className="right-text">projects per month</td>
            </tr>
            {
              // ------------------------------------------------------------------------------
            }
            <tr>
              <td colSpan={2}>Number of questions per project</td>
              <td>
                <input
                  type="number"
                  value={numberOfQuestionsPerProject}
                  onChange={(e) => {
                    setNumberOfQuestionsPerProject(parseInt(e.target.value));
                  }}
                />
              </td>
              <td className="right-text">questions per project</td>
            </tr>
            {
              // ------------------------------------------------------------------------------
            }
            <tr>
              <td colSpan={2}>How many languages do you typically use for a project?</td>
              <td>
                <input
                  type="number"
                  value={numberOfLanguagesPerProject}
                  onChange={(e) => {
                    setNumberOfLanguagesPerProject(parseInt(e.target.value));
                  }}
                />
              </td>
              <td className="right-text">languages per project</td>
            </tr>
            {
              // ------------------------------------------------------------------------------
            }
            <tr>
              <td colSpan={2}>
                How many issues do your testers detect per project across all languages?
              </td>
              <td>
                <input
                  type="number"
                  value={numberOfIssuesPerProject}
                  onChange={(e) => {
                    setNumberOfIssuesPerProject(parseInt(e.target.value));
                  }}
                />
              </td>
              <td className="right-text">issues per project</td>
            </tr>
            {
              // ------------------------------------------------------------------------------
            }
            <tr>
              <td colSpan={2}>
                How many testruns do your survey authors need to replay per project?
              </td>
              <td>
                <input
                  type="number"
                  value={numberOfReplaysPerProject}
                  onChange={(e) => {
                    setNumberOfReplaysPerProject(parseInt(e.target.value));
                  }}
                />
              </td>
              <td className="right-text">replays per project</td>
            </tr>
            {
              // ------------------------------------------------------------------------------
            }
            <tr>
              <td colSpan={2}>Average hourly rate of team members involved in QA.</td>
              <td>
                <input
                  type="number"
                  value={costPerHour}
                  onChange={(e) => {
                    setCostPerHour(parseInt(e.target.value));
                  }}
                />
              </td>
              <td className="right-text">{props.Currency}</td>
            </tr>
            {
              // ############################################################################
            }
            <tr>
              <td colSpan={2}>
                <h3>Check all options your team should work on.</h3>
              </td>
              <td className="td-center">
                <h3>Saved Hours</h3>
              </td>
              <td className="td-center">
                <h3>Saved {props.Currency}</h3>
              </td>
            </tr>
            <tr>
              <td>
                <input
                  id="calcScreenshots"
                  type="checkbox"
                  checked={calcScreenshots}
                  onChange={() => {
                    setCalcScreenshots(!calcScreenshots);
                  }}
                />
              </td>
              <td>
                <label htmlFor="calcScreenshots">Create Screenshots for found issues</label>

                <div className="explain">
                  We calulate 2 minutes to generate a screenshot, save it on a shared drive, and
                  reference it to the reported issue
                </div>
              </td>
              <td className="td-center">{minutesScreenshots}</td>
              <td className="td-center">{Math.round(minutesScreenshots * costPerHour)}</td>
            </tr>
            {
              // ------------------------------------------------------------------------------
            }
            <tr>
              <td>
                <input
                  id="calcCheckExistingIssues"
                  type="checkbox"
                  checked={calcCheckExistingIssues}
                  onChange={() => {
                    setCalcCheckExistingIssues(!calcCheckExistingIssues);
                  }}
                />
              </td>
              <td>
                <label htmlFor="calcCheckExistingIssues">
                  Check existing issues before creating new ones
                </label>

                <div className="explain">
                  We calulate 1 minutes to check if somebody else already created an issue.
                </div>
              </td>
              <td className="td-center">{minutesCheckExistingIssues}</td>
              <td className="td-center">{Math.round(minutesCheckExistingIssues * costPerHour)}</td>
            </tr>
            {
              // ------------------------------------------------------------------------------
            }
            <tr>
              <td>
                <input
                  id="calcReplayInterview"
                  type="checkbox"
                  checked={calcReplayInterview}
                  onChange={() => {
                    setCalcReplayInterview(!calcReplayInterview);
                  }}
                />
              </td>
              <td>
                <label htmlFor="calcReplayInterview">
                  Survey Authors time to replay an interview with the tester's answers{' '}
                </label>

                <div className="explain">
                  We calulate in average 30s for each question in the survey, including database
                  lookups, etc.
                </div>
              </td>
              <td className="td-center">{minutesReplayInterview}</td>
              <td className="td-center">{Math.round(minutesReplayInterview * costPerHour)}</td>
            </tr>
            {
              // ------------------------------------------------------------------------------
            }
            <tr>
              <td>
                <input
                  id="calcDummyData"
                  type="checkbox"
                  checked={calcDummyData}
                  onChange={() => {
                    setCalcDummyData(!calcDummyData);
                  }}
                />
              </td>
              <td>
                <label htmlFor="calcDummyData">
                  Create 20 interviews to analyze the survey workflow
                </label>
                <div className="explain">
                  We calulate in average 15s for each question in the survey.
                </div>
              </td>
              <td className="td-center">{minutesDummyData}</td>
              <td className="td-center">{Math.round(minutesDummyData * costPerHour)}</td>
            </tr>
            {
              // ------------------------------------------------------------------------------
            }
            <tr>
              <td>
                <input
                  id="calcTeamPerformance"
                  type="checkbox"
                  checked={calcTeamPerformance}
                  onChange={() => {
                    setCalcTeamPerformance(!calcTeamPerformance);
                  }}
                />
              </td>
              <td>
                <label htmlFor="calcTeamPerformance">Analyze QA team performance </label>

                <div className="explain">
                  We calulate in average of 1h per project to gather and analyze the teammates
                  efforts.
                </div>
              </td>
              <td className="td-center">{minutesTeamPerformance}</td>
              <td className="td-center">{Math.round(minutesTeamPerformance * costPerHour)}</td>
            </tr>

            <tr>
              <td>&nbsp;</td>
              <td>
                <h3>Total</h3>
              </td>
              <td className="td-center">
                <h3>{hoursTotal}</h3>
              </td>
              <td className="td-center">
                <h3>{Math.round(hoursTotal * costPerHour)}</h3>
              </td>
            </tr>
            <tr>
              <td>&nbsp;</td>
              <td>
                <h3>Costs for SurveyTester</h3>
                (prepaid 100)
              </td>
              <td className="td-center"></td>
              <td className="td-center">
                <h3>
                  -{Math.round(numberOfProjectsPerMonth * parseInt(props.costPerProject))} &nbsp;
                </h3>
              </td>
            </tr>
            <tr>
              <td>&nbsp;</td>
              <td style={{ textDecoration: 'underline', fontSize: '1.5rem' }}>
                <h3>Total Savings per month</h3>
              </td>
              <td className="td-center">
                <h3 style={{ textDecoration: 'underline', fontSize: '1.5rem' }}>
                  {hoursTotal} hrs *
                </h3>
              </td>
              <td className="td-center">
                <h3 style={{ textDecoration: 'underline', fontSize: '1.5rem' }}>
                  {Math.round(hoursTotal * costPerHour) -
                    Math.round(numberOfProjectsPerMonth * parseInt(props.costPerProject))}
                  {' ' + props.Currency} *
                </h3>
              </td>
            </tr>
          </tbody>
        </table>
        <br />
        <p style={{ fontSize: '0.9rem' }}>
          * These numbers are illustrative examples and your own savings depend on your unique cost
          structure, used software and business preferences. They are intended to provide a general
          idea of potential savings, rather than precise calculations.
        </p>
      </div>
    </>
  );
};

export default Calculator;
