import { useState, useEffect, useCallback, Fragment } from 'react';
import { FunctionComponent } from 'react';

import { useNavigate } from 'react-router-dom';
import Footer from '../shared/footer/footer';
import TopMenu from '../shared/top-menu/top-menu';

export type Props = {};

const Privacy: FunctionComponent<Props> = (props) => {
  const history = useNavigate();

  // ----------------------------------------------------------------
  // ----------------------------------------------------------------
  // ----------------------------------------------------------------
  // ----------------------------------------------------------------
  // ----------------------------------------------------------------
  return (
    <div className="page">
      <TopMenu selected="privacy"></TopMenu>
      <div className="page-content bg-top">
        <div className="inner-content">
          <h1>Privacy Policy</h1>
        </div>
      </div>
      <div className="page-content bg-1">
        <div className="inner-content">
          <h2>1. An overview of data protection</h2>
          <h3>General information</h3>{' '}
          <p>
            The following information will provide you with an easy to navigate overview of what
            will happen with your personal data when you visit this website. The term
            &ldquo;personal data&rdquo; comprises all data that can be used to personally identify
            you. For detailed information about the subject matter of data protection, please
            consult our Data Protection Declaration, which we have included beneath this copy.
          </p>
          <h3>Data recording on this website</h3>{' '}
          <h4>
            Who is the responsible party for the recording of data on this website (i.e., the
            &ldquo;controller&rdquo;)?
          </h4>{' '}
          <p>
            The data on this website is processed by the operator of the website, whose contact
            information is available under section &ldquo;Information about the responsible party
            (referred to as the &ldquo;controller&rdquo; in the GDPR)&rdquo; in this Privacy Policy.
          </p>{' '}
          <h4>How do we record your data?</h4>{' '}
          <p>
            We collect your data as a result of your sharing of your data with us. This may, for
            instance be information you enter into our contact form.
          </p>{' '}
          <p>
            Other data shall be recorded by our IT systems automatically or after you consent to its
            recording during your website visit. This data comprises primarily technical information
            (e.g., web browser, operating system, or time the site was accessed). This information
            is recorded automatically when you access this website.
          </p>{' '}
          <h4>What are the purposes we use your data for?</h4>{' '}
          <p>
            A portion of the information is generated to guarantee the error free provision of the
            website. Other data may be used to analyze your user patterns.
          </p>{' '}
          <h4>What rights do you have as far as your information is concerned?</h4>{' '}
          <p>
            You have the right to receive information about the source, recipients, and purposes of
            your archived personal data at any time without having to pay a fee for such
            disclosures. You also have the right to demand that your data are rectified or
            eradicated. If you have consented to data processing, you have the option to revoke this
            consent at any time, which shall affect all future data processing. Moreover, you have
            the right to demand that the processing of your data be restricted under certain
            circumstances. Furthermore, you have the right to log a complaint with the competent
            supervising agency.
          </p>{' '}
          <p>
            Please do not hesitate to contact us at any time if you have questions about this or any
            other data protection related issues.
          </p>
          <h2>2. General information and mandatory information</h2>
          <h3>Data protection</h3>{' '}
          <p>
            The operators of this website and its pages take the protection of your personal data
            very seriously. Hence, we handle your personal data as confidential information and in
            compliance with the statutory data protection regulations and this Data Protection
            Declaration.
          </p>{' '}
          <p>
            Whenever you use this website, a variety of personal information will be collected.
            Personal data comprises data that can be used to personally identify you. This Data
            Protection Declaration explains which data we collect as well as the purposes we use
            this data for. It also explains how, and for which purpose the information is collected.
          </p>{' '}
          <p>
            We herewith advise you that the transmission of data via the Internet (i.e., through
            e-mail communications) may be prone to security gaps. It is not possible to completely
            protect data against third-party access.
          </p>
          <h3>
            Information about the responsible party (referred to as the &ldquo;controller&rdquo; in
            the GDPR)
          </h3>{' '}
          <p>The data processing controller on this website is:</p>{' '}
          <p>
            2x4 Solutions GmbH
            <br />
            Fichtenring 8<br />
            84562 Mettenheim
          </p>
          <p>
            Phone: +49 8631 3516525
            <br />
            E-mail: info@surveytester.com
          </p>
          <p>
            The controller is the natural person or legal entity that single-handedly or jointly
            with others makes decisions as to the purposes of and resources for the processing of
            personal data (e.g., names, e-mail addresses, etc.).
          </p>
          <h3>Storage duration</h3>{' '}
          <p>
            Unless a more specific storage period has been specified in this privacy policy, your
            personal data will remain with us until the purpose for which it was collected no longer
            applies. If you assert a justified request for deletion or revoke your consent to data
            processing, your data will be deleted, unless we have other legally permissible reasons
            for storing your personal data (e.g., tax or commercial law retention periods); in the
            latter case, the deletion will take place after these reasons cease to apply.
          </p>
          <h3>General information on the legal basis for the data processing on this website</h3>{' '}
          <p>
            If you have consented to data processing, we process your personal data on the basis of
            Art. 6(1)(a) GDPR or Art. 9 (2)(a) GDPR, if special categories of data are processed
            according to Art. 9 (1) DSGVO. In the case of explicit consent to the transfer of
            personal data to third countries, the data processing is also based on Art. 49 (1)(a)
            GDPR. If you have consented to the storage of cookies or to the access to information in
            your end device (e.g., via device fingerprinting), the data processing is additionally
            based on &sect; 25 (1) TDDDG. The consent can be revoked at any time. If your data is
            required for the fulfillment of a contract or for the implementation of pre-contractual
            measures, we process your data on the basis of Art. 6(1)(b) GDPR. Furthermore, if your
            data is required for the fulfillment of a legal obligation, we process it on the basis
            of Art. 6(1)(c) GDPR. Furthermore, the data processing may be carried out on the basis
            of our legitimate interest according to Art. 6(1)(f) GDPR. Information on the relevant
            legal basis in each individual case is provided in the following paragraphs of this
            privacy policy.
          </p>
          <h3>
            Information on the data transfer to third-party countries that are not secure under data
            protection law and the transfer to US companies that are not DPF-certified
          </h3>{' '}
          <p>
            We use, among other technologies, tools from companies located in third-party countries
            that are not safe under data protection law, as well as US tools whose providers are not
            certified under the EU-US Data Privacy Framework (DPF). If these tools are enabled, your
            personal data may be transferred to and processed in these countries. We would like you
            to note that no level of data protection comparable to that in the EU can be guaranteed
            in third countries that are insecure in terms of data protection law.
          </p>{' '}
          <p>
            We would like to point out that the US, as a secure third-party country, generally has a
            level of data protection comparable to that of the EU. Data transfer to the US is
            therefore permitted if the recipient is certified under the &ldquo;EU-US Data Privacy
            Framework&rdquo; (DPF) or has appropriate additional assurances. Information on
            transfers to third-party countries, including the data recipients, can be found in this
            Privacy Policy.
          </p>
          <h3>Recipients of personal data</h3>{' '}
          <p>
            In the scope of our business activities, we cooperate with various external parties. In
            some cases, this also requires the transfer of personal data to these external parties.
            We only disclose personal data to external parties if this is required as part of the
            fulfillment of a contract, if we are legally obligated to do so (e.g., disclosure of
            data to tax authorities), if we have a legitimate interest in the disclosure pursuant to
            Art. 6 (1)(f) GDPR, or if another legal basis permits the disclosure of this data. When
            using processors, we only disclose personal data of our customers on the basis of a
            valid contract on data processing. In the case of joint processing, a joint processing
            agreement is concluded.
          </p>
          <h3>Revocation of your consent to the processing of data</h3>{' '}
          <p>
            A wide range of data processing transactions are possible only subject to your express
            consent. You can also revoke at any time any consent you have already given us. This
            shall be without prejudice to the lawfulness of any data collection that occurred prior
            to your revocation.
          </p>
          <h3>
            Right to object to the collection of data in special cases; right to object to direct
            advertising (Art. 21 GDPR)
          </h3>{' '}
          <p>
            IN THE EVENT THAT DATA ARE PROCESSED ON THE BASIS OF ART. 6(1)(E) OR (F) GDPR, YOU HAVE
            THE RIGHT TO AT ANY TIME OBJECT TO THE PROCESSING OF YOUR PERSONAL DATA BASED ON GROUNDS
            ARISING FROM YOUR UNIQUE SITUATION. THIS ALSO APPLIES TO ANY PROFILING BASED ON THESE
            PROVISIONS. TO DETERMINE THE LEGAL BASIS, ON WHICH ANY PROCESSING OF DATA IS BASED,
            PLEASE CONSULT THIS DATA PROTECTION DECLARATION. IF YOU LOG AN OBJECTION, WE WILL NO
            LONGER PROCESS YOUR AFFECTED PERSONAL DATA, UNLESS WE ARE IN A POSITION TO PRESENT
            COMPELLING PROTECTION WORTHY GROUNDS FOR THE PROCESSING OF YOUR DATA, THAT OUTWEIGH YOUR
            INTERESTS, RIGHTS AND FREEDOMS OR IF THE PURPOSE OF THE PROCESSING IS THE CLAIMING,
            EXERCISING OR DEFENCE OF LEGAL ENTITLEMENTS (OBJECTION PURSUANT TO ART. 21(1) GDPR).
          </p>{' '}
          <p>
            IF YOUR PERSONAL DATA IS BEING PROCESSED IN ORDER TO ENGAGE IN DIRECT ADVERTISING, YOU
            HAVE THE RIGHT TO OBJECT TO THE PROCESSING OF YOUR AFFECTED PERSONAL DATA FOR THE
            PURPOSES OF SUCH ADVERTISING AT ANY TIME. THIS ALSO APPLIES TO PROFILING TO THE EXTENT
            THAT IT IS AFFILIATED WITH SUCH DIRECT ADVERTISING. IF YOU OBJECT, YOUR PERSONAL DATA
            WILL SUBSEQUENTLY NO LONGER BE USED FOR DIRECT ADVERTISING PURPOSES (OBJECTION PURSUANT
            TO ART. 21(2) GDPR).
          </p>
          <h3>Right to log a complaint with the competent supervisory agency</h3>{' '}
          <p>
            In the event of violations of the GDPR, data subjects are entitled to log a complaint
            with a supervisory agency, in particular in the member state where they usually maintain
            their domicile, place of work or at the place where the alleged violation occurred. The
            right to log a complaint is in effect regardless of any other administrative or court
            proceedings available as legal recourses.
          </p>
          <h3>Right to data portability</h3>{' '}
          <p>
            You have the right to have data that we process automatically on the basis of your
            consent or in fulfillment of a contract handed over to you or to a third party in a
            common, machine-readable format. If you should demand the direct transfer of the data to
            another controller, this will be done only if it is technically feasible.
          </p>
          <h3>Information about, rectification and eradication of data</h3>{' '}
          <p>
            Within the scope of the applicable statutory provisions, you have the right to demand
            information about your archived personal data, their source and recipients as well as
            the purpose of the processing of your data at any time. You may also have a right to
            have your data rectified or eradicated. If you have questions about this subject matter
            or any other questions about personal data, please do not hesitate to contact us at any
            time.
          </p>
          <h3>Right to demand processing restrictions</h3>{' '}
          <p>
            You have the right to demand the imposition of restrictions as far as the processing of
            your personal data is concerned. To do so, you may contact us at any time. The right to
            demand restriction of processing applies in the following cases:
          </p>{' '}
          <ul>
            {' '}
            <li>
              In the event that you should dispute the correctness of your data archived by us, we
              will usually need some time to verify this claim. During the time that this
              investigation is ongoing, you have the right to demand that we restrict the processing
              of your personal data.
            </li>{' '}
            <li>
              If the processing of your personal data was/is conducted in an unlawful manner, you
              have the option to demand the restriction of the processing of your data instead of
              demanding the eradication of this data.
            </li>{' '}
            <li>
              If we do not need your personal data any longer and you need it to exercise, defend or
              claim legal entitlements, you have the right to demand the restriction of the
              processing of your personal data instead of its eradication.
            </li>{' '}
            <li>
              If you have raised an objection pursuant to Art. 21(1) GDPR, your rights and our
              rights will have to be weighed against each other. As long as it has not been
              determined whose interests prevail, you have the right to demand a restriction of the
              processing of your personal data.
            </li>{' '}
          </ul>{' '}
          <p>
            If you have restricted the processing of your personal data, these data &ndash; with the
            exception of their archiving &ndash; may be processed only subject to your consent or to
            claim, exercise or defend legal entitlements or to protect the rights of other natural
            persons or legal entities or for important public interest reasons cited by the European
            Union or a member state of the EU.
          </p>
          <h3>SSL and/or TLS encryption</h3>{' '}
          <p>
            For security reasons and to protect the transmission of confidential content, such as
            purchase orders or inquiries you submit to us as the website operator, this website uses
            either an SSL or a TLS encryption program. You can recognize an encrypted connection by
            checking whether the address line of the browser switches from &ldquo;http://&rdquo; to
            &ldquo;https://&rdquo; and also by the appearance of the lock icon in the browser line.
          </p>{' '}
          <p>
            If the SSL or TLS encryption is activated, data you transmit to us cannot be read by
            third parties.
          </p>
          <h3>Encrypted payment transactions on this website</h3>{' '}
          <p>
            If you are under an obligation to share your payment information (e.g. account number if
            you give us the authority to debit your bank account) with us after you have entered
            into a fee-based contract with us, this information is required to process payments.
          </p>{' '}
          <p>
            Payment transactions using common modes of paying (Visa/MasterCard, debit to your bank
            account) are processed exclusively via encrypted SSL or TLS connections. You can
            recognize an encrypted connection by checking whether the address line of the browser
            switches from &ldquo;http://&rdquo; to &ldquo;https://&rdquo; and also by the appearance
            of the lock icon in the browser line.
          </p>{' '}
          <p>
            If the communication with us is encrypted, third parties will not be able to read the
            payment information you share with us.
          </p>
          <h3>Rejection of unsolicited e-mails</h3>{' '}
          <p>
            We herewith object to the use of contact information published in conjunction with the
            mandatory information to be provided in our Site Notice to send us promotional and
            information material that we have not expressly requested. The operators of this website
            and its pages reserve the express right to take legal action in the event of the
            unsolicited sending of promotional information, for instance via SPAM messages.
          </p>
          <h2>3. Recording of data on this website</h2>
          <h3>Cookies</h3>{' '}
          <p>
            Our websites and pages use what the industry refers to as &ldquo;cookies.&rdquo; Cookies
            are small data packages that do not cause any damage to your device. They are either
            stored temporarily for the duration of a session (session cookies) or they are
            permanently archived on your device (permanent cookies). Session cookies are
            automatically deleted once you terminate your visit. Permanent cookies remain archived
            on your device until you actively delete them, or they are automatically eradicated by
            your web browser.
          </p>{' '}
          <p>
            Cookies can be issued by us (first-party cookies) or by third-party companies (so-called
            third-party cookies). Third-party cookies enable the integration of certain services of
            third-party companies into websites (e.g., cookies for handling payment services).
          </p>{' '}
          <p>
            Cookies have a variety of functions. Many cookies are technically essential since
            certain website functions would not work in the absence of these cookies (e.g., the
            shopping cart function or the display of videos). Other cookies may be used to analyze
            user behavior or for promotional purposes.
          </p>{' '}
          <p>
            Cookies, which are required for the performance of electronic communication
            transactions, for the provision of certain functions you want to use (e.g., for the
            shopping cart function) or those that are necessary for the optimization (required
            cookies) of the website (e.g., cookies that provide measurable insights into the web
            audience), shall be stored on the basis of Art. 6(1)(f) GDPR, unless a different legal
            basis is cited. The operator of the website has a legitimate interest in the storage of
            required cookies to ensure the technically error-free and optimized provision of the
            operator&rsquo;s services. If your consent to the storage of the cookies and similar
            recognition technologies has been requested, the processing occurs exclusively on the
            basis of the consent obtained (Art. 6(1)(a) GDPR and &sect; 25 (1) TDDDG); this consent
            may be revoked at any time.
          </p>{' '}
          <p>
            You have the option to set up your browser in such a manner that you will be notified
            any time cookies are placed and to permit the acceptance of cookies only in specific
            cases. You may also exclude the acceptance of cookies in certain cases or in general or
            activate the delete-function for the automatic eradication of cookies when the browser
            closes. If cookies are deactivated, the functions of this website may be limited.
          </p>{' '}
          <p>
            Which cookies and services are used on this website can be found in this privacy policy.
          </p>
          <h3>Server log files</h3>{' '}
          <p>
            The provider of this website and its pages automatically collects and stores information
            in so-called server log files, which your browser communicates to us automatically. The
            information comprises:
          </p>{' '}
          <ul>
            {' '}
            <li>The type and version of browser used</li> <li>The used operating system</li>{' '}
            <li>Referrer URL</li> <li>The hostname of the accessing computer</li>{' '}
            <li>The time of the server inquiry</li> <li>The IP address</li>{' '}
          </ul>{' '}
          <p>This data is not merged with other data sources.</p>{' '}
          <p>
            This data is recorded on the basis of Art. 6(1)(f) GDPR. The operator of the website has
            a legitimate interest in the technically error free depiction and the optimization of
            the operator&rsquo;s website. In order to achieve this, server log files must be
            recorded.
          </p>
          <h3>Contact form</h3>{' '}
          <p>
            If you submit inquiries to us via our contact form, the information provided in the
            contact form as well as any contact information provided therein will be stored by us in
            order to handle your inquiry and in the event that we have further questions. We will
            not share this information without your consent.
          </p>{' '}
          <p>
            The processing of these data is based on Art. 6(1)(b) GDPR, if your request is related
            to the execution of a contract or if it is necessary to carry out pre-contractual
            measures. In all other cases the processing is based on our legitimate interest in the
            effective processing of the requests addressed to us (Art. 6(1)(f) GDPR) or on your
            agreement (Art. 6(1)(a) GDPR) if this has been requested; the consent can be revoked at
            any time.
          </p>{' '}
          <p>
            The information you have entered into the contact form shall remain with us until you
            ask us to eradicate the data, revoke your consent to the archiving of data or if the
            purpose for which the information is being archived no longer exists (e.g., after we
            have concluded our response to your inquiry). This shall be without prejudice to any
            mandatory legal provisions, in particular retention periods.
          </p>
          <h3>Request by e-mail, telephone, or fax</h3>{' '}
          <p>
            If you contact us by e-mail, telephone or fax, your request, including all resulting
            personal data (name, request) will be stored and processed by us for the purpose of
            processing your request. We do not pass these data on without your consent.
          </p>{' '}
          <p>
            These data are processed on the basis of Art. 6(1)(b) GDPR if your inquiry is related to
            the fulfillment of a contract or is required for the performance of pre-contractual
            measures. In all other cases, the data are processed on the basis of our legitimate
            interest in the effective handling of inquiries submitted to us (Art. 6(1)(f) GDPR) or
            on the basis of your consent (Art. 6(1)(a) GDPR) if it has been obtained; the consent
            can be revoked at any time.
          </p>{' '}
          <p>
            The data sent by you to us via contact requests remain with us until you request us to
            delete, revoke your consent to the storage or the purpose for the data storage lapses
            (e.g. after completion of your request). Mandatory statutory provisions - in particular
            statutory retention periods - remain unaffected.
          </p>
          <h3>Registration on this website</h3>{' '}
          <p>
            You have the option to register on this website to be able to use additional website
            functions. We shall use the data you enter only for the purpose of using the respective
            offer or service you have registered for. The required information we request at the
            time of registration must be entered in full. Otherwise, we shall reject the
            registration.
          </p>{' '}
          <p>
            To notify you of any important changes to the scope of our portfolio or in the event of
            technical modifications, we shall use the e-mail address provided during the
            registration process.
          </p>{' '}
          <p>
            We shall process the data entered during the registration process on the basis of your
            consent (Art. 6(1)(a) GDPR).
          </p>{' '}
          <p>
            The data recorded during the registration process shall be stored by us as long as you
            are registered on this website. Subsequently, such data shall be deleted. This shall be
            without prejudice to mandatory statutory retention obligations.
          </p>
          <h2>4. Newsletter</h2>
          <h3>Newsletter data</h3>{' '}
          <p>
            If you would like to receive the newsletter offered on the website, we require an e-mail
            address from you as well as information that allows us to verify that you are the owner
            of the e-mail address provided and that you agree to receive the newsletter. Further
            data is not collected or only on a voluntary basis. For the handling of the newsletter,
            we use newsletter service providers, which are described below.
          </p>
          <h3>Brevo</h3>{' '}
          <p>
            This website uses Brevo for the sending of newsletters. The provider is the Sendinblue
            GmbH, K&ouml;penicker Stra&szlig;e 126, 10179 Berlin, Germany.
          </p>{' '}
          <p>
            Brevo services can, among other things, be used to organize and analyze the sending of
            newsletters. The data you enter for the purpose of subscribing to the newsletter are
            archived on servers of Sendinblue GmbH in Germany.
          </p>{' '}
          <h4>Data analysis by Brevo</h4>{' '}
          <p>
            Brevo enables us to analyze our newsletter campaigns. For instance, it allows us to see
            whether a newsletter message has been opened and, if so, which links may have been
            clicked. This enables us to determine, which links drew an extraordinary number of
            clicks.
          </p>{' '}
          <p>
            Moreover, we are also able to see whether once the e-mail was opened or a link was
            clicked, any previously defined actions were taken (conversion rate). This allows us to
            determine whether you have made a purchase after clicking on the newsletter.
          </p>{' '}
          <p>
            Brevo also enables us to divide the subscribers to our newsletter into various
            categories (i.e., to &ldquo;cluster&rdquo; recipients). For instance, newsletter
            recipients can be categorized based on age, gender, or place of residence. This enables
            us to tailor our newsletter more effectively to the needs of the respective target
            groups.
          </p>{' '}
          <p>
            If you do not want to permit an analysis by Brevo, you must unsubscribe from the
            newsletter. We provide a link for you to do this in every newsletter message. Moreover,
            you can also unsubscribe from the newsletter right on the website.
          </p>{' '}
          <p>
            For detailed information on the functions of Brevo please follow this link:{' '}
            <a
              href="https://www.brevo.com/de/newsletter-software/"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.brevo.com/de/newsletter-software/
            </a>
            .
          </p>{' '}
          <h4>Legal basis</h4>{' '}
          <p>
            The data is processed based on your consent (Art. 6(1)(a) GDPR). You may revoke any
            consent you have given at any time by unsubscribing from the newsletter. This shall be
            without prejudice to the lawfulness of any data processing transactions that have taken
            place prior to your revocation.
          </p>{' '}
          <h4>Storage period</h4>{' '}
          <p>
            The data deposited with us for the purpose of subscribing to the newsletter will be
            stored by us until you unsubscribe from the newsletter or the newsletter service
            provider and deleted from the newsletter distribution list after you unsubscribe from
            the newsletter. Data stored for other purposes with us remain unaffected.
          </p>{' '}
          <p>
            After you unsubscribe from the newsletter distribution list, your e-mail address may be
            stored by us or the newsletter service provider in a blacklist, if such action is
            necessary to prevent future mailings. The data from the blacklist is used only for this
            purpose and not merged with other data. This serves both your interest and our interest
            in complying with the legal requirements when sending newsletters (legitimate interest
            within the meaning of Art. 6(1)(f) GDPR). The storage in the blacklist is indefinite.{' '}
            <strong>
              You may object to the storage if your interests outweigh our legitimate interest.
            </strong>
          </p>{' '}
          <p>
            For more details, please consult the Data Protection Regulations of Brevo at:{' '}
            <a
              href="https://www.brevo.com/de/datenschutz-uebersicht/"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.brevo.com/de/datenschutz-uebersicht/
            </a>{' '}
            and{' '}
            <a
              href="https://www.brevo.com/de/legal/privacypolicy/"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.brevo.com/de/legal/privacypolicy/
            </a>
            .
          </p>
          <h4>Data processing</h4>{' '}
          <p>
            We have concluded a data processing agreement (DPA) for the use of the above-mentioned
            service. This is a contract mandated by data privacy laws that guarantees that they
            process personal data of our website visitors only based on our instructions and in
            compliance with the GDPR.
          </p>
          <h2>5. Plug-ins and Tools</h2>
          <h3>YouTube with expanded data protection integration</h3>{' '}
          <p>
            This website integrates videos from the YouTube website. The operator of the website is
            Google Ireland Limited (&ldquo;Google&rdquo;), Gordon House, Barrow Street, Dublin 4,
            Ireland.
          </p>{' '}
          <p>
            When you visit one of these websites on which YouTube is integrated, a connection to the
            YouTube servers is established. This tells the YouTube server which of our pages you
            have visited. If you are logged into your YouTube account, you enable YouTube to assign
            your surfing behavior directly to your personal profile. You can prevent this by logging
            out of your YouTube account.
          </p>{' '}
          <p>
            We use YouTube in extended data protection mode. According to YouTube, videos that are
            played in extended data protection mode are not used to personalize browsing on YouTube.
            Ads that are played in extended data protection mode are also not personalized. No
            cookies are set in extended data protection mode. Instead, so-called local storage
            elements are stored in the user's browser, which contain personal data similar to
            cookies and can be used for recognition. Details on the extended data protection mode
            can be found here:{' '}
            <a
              href="https://support.google.com/youtube/answer/171780"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://support.google.com/youtube/answer/171780
            </a>
            .
          </p>{' '}
          <p>
            After activating a YouTube video, further data processing operations may be triggered
            over which we have no influence.
          </p>
          <p>
            The use of YouTube is based on our interest in presenting our online content in an
            appealing manner. Pursuant to Art. 6(1)(f) GDPR, this is a legitimate interest. If
            appropriate consent has been obtained, the processing is carried out exclusively on the
            basis of Art. 6(1)(a) GDPR and &sect; 25 (1) TDDDG, insofar the consent includes the
            storage of cookies or the access to information in the user&rsquo;s end device (e.g.,
            device fingerprinting) within the meaning of the TDDDG. This consent can be revoked at
            any time.
          </p>{' '}
          <p>
            For more information on how YouTube handles user data, please consult the YouTube Data
            Privacy Policy under:{' '}
            <a
              href="https://policies.google.com/privacy?hl=en"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://policies.google.com/privacy?hl=en
            </a>
            .
          </p>
          <p>
            The company is certified in accordance with the &ldquo;EU-US Data Privacy
            Framework&rdquo; (DPF). The DPF is an agreement between the European Union and the US,
            which is intended to ensure compliance with European data protection standards for data
            processing in the US. Every company certified under the DPF is obliged to comply with
            these data protection standards. For more information, please contact the provider under
            the following link:{' '}
            <a
              href="https://www.dataprivacyframework.gov/s/participant-search/participant-detail?contact=true&id=a2zt000000001L5AAI&status=Active"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.dataprivacyframework.gov/s/participant-search/participant-detail?contact=true&id=a2zt000000001L5AAI&status=Active
            </a>
          </p>
          <h3>Vimeo Without Tracking (Do-Not-Track)</h3>{' '}
          <p>
            This website uses plugins of the Vimeo video portal. The provider is Vimeo Inc., 555
            West 18th Street, New York, New York 10011, USA.
          </p>{' '}
          <p>
            Whenever you visit one of our pages featuring Vimeo videos, a connection with the
            servers of Vimeo is established. In conjunction with this, the Vimeo server receives
            information about which of our sites you have visited. Vimeo also receives your IP
            address. However, we have set up Vimeo in such a way that Vimeo cannot track your user
            activities and does not place any cookies.
          </p>{' '}
          <p>
            We use Vimeo to make our online presentation attractive for you. This is a legitimate
            interest on our part pursuant to Art. 6(1)(f) GDPR. If a respective declaration of
            consent was requested (e.g. concerning the storage of cookies), processing shall occur
            exclusively on the basis of Art. 6(1)(a) GDPR; the given consent may be revoked at any
            time.
          </p>{' '}
          <p>
            Data transmission to the US is based on the Standard Contractual Clauses (SCC) of the
            European Commission and, according to Vimeo, on &ldquo;legitimate business
            interests&rdquo;. Details can be found here:{' '}
            <a href="https://vimeo.com/privacy" target="_blank" rel="noopener noreferrer">
              https://vimeo.com/privacy
            </a>
            .
          </p>{' '}
          <p>
            For more information on the handling of user data, please consult Vimeo&rsquo;s data
            privacy policy at:{' '}
            <a href="https://vimeo.com/privacy" target="_blank" rel="noopener noreferrer">
              https://vimeo.com/privacy
            </a>
          </p>
          <p>
            The company is certified in accordance with the &ldquo;EU-US Data Privacy
            Framework&rdquo; (DPF). The DPF is an agreement between the European Union and the US,
            which is intended to ensure compliance with European data protection standards for data
            processing in the US. Every company certified under the DPF is obliged to comply with
            these data protection standards. For more information, please contact the provider under
            the following link:{' '}
            <a
              href="https://www.dataprivacyframework.gov/list"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.dataprivacyframework.gov/list
            </a>
          </p>
          <h3>WebinarJam</h3>{' '}
          <p>
            We use WebinarJam to host and manage online webinars. The provider of this service is
            Genesis LLC, 7660 Fay Ave #H184, La Jolla, California (USA).
          </p>{' '}
          <p>
            When you participate in one of our webinars, your personal data is stored on
            WebinarJam&rsquo;s servers. This includes, in particular, your IP address, as well as
            any content that you enter into WebinarJam yourself (e.g., your email address or chat
            messages).
          </p>{' '}
          <p>
            The legal basis for the use of WebinarJam is Art. 6(1)(b) GDPR (contract fulfillment)
            and our legitimate interest in a professional handling of our webinars (Art. 6(1)(f)
            GDPR). If appropriate consent has been obtained, the processing is carried out
            exclusively on the basis of Art. 6(1)(a) GDPR and &sect; 25 (1) TDDDG, insofar the
            consent includes the storage of cookies or the access to information in the user&rsquo;s
            end device (e.g., device fingerprinting) within the meaning of the TDDDG. This consent
            can be revoked at any time.
          </p>{' '}
          <p>
            Data transfer to the US is based on the standard contractual clauses of the EU
            Commission. Details can be found here:{' '}
            <a href="https://home.webinarjam.com/dpa" target="_blank" rel="noopener noreferrer">
              https://home.webinarjam.com/dpa
            </a>
            .
          </p>{' '}
          <p>
            For details on data processing, please refer to WebinarJam&rsquo;s privacy policy:{' '}
            <a
              href="https://home.webinarjam.com/privacypolicy"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://home.webinarjam.com/privacypolicy
            </a>
            .
          </p>
          <h4>Data processing</h4>{' '}
          <p>
            We have concluded a data processing agreement (DPA) for the use of the above-mentioned
            service. This is a contract mandated by data privacy laws that guarantees that they
            process personal data of our website visitors only based on our instructions and in
            compliance with the GDPR.
          </p>
          <h2>6. eCommerce and payment service providers</h2>
          <h3>Processing of Customer and Contract Data</h3>{' '}
          <p>
            We collect, process, and use personal customer and contract data for the establishment,
            content arrangement and modification of our contractual relationships. Data with
            personal references to the use of this website (usage data) will be collected,
            processed, and used only if this is necessary to enable the user to use our services or
            required for billing purposes. The legal basis for these processes is Art. 6(1)(b) GDPR.
          </p>{' '}
          <p>
            The collected customer data shall be deleted upon completion of the order or termination
            of the business relationship and upon expiration of any existing statutory archiving
            periods. This shall be without prejudice to any statutory archiving periods.
          </p>
          <h3>Data transfer upon closing of contracts for services and digital content</h3>{' '}
          <p>
            We share personal data with third parties only if this is necessary in conjunction with
            the handling of the contract; for instance, with the financial institution tasked with
            the processing of payments.
          </p>{' '}
          <p>
            Any further transfer of data shall not occur or shall only occur if you have expressly
            consented to the transfer. Any sharing of your data with third parties in the absence of
            your express consent, for instance for advertising purposes, shall not occur.
          </p>{' '}
          <p>
            The basis for the processing of data is Art. 6(1)(b) GDPR, which permits the processing
            of data for the fulfilment of a contract or for pre-contractual actions.
          </p>
          <h3>Payment services</h3>{' '}
          <p>
            We integrate payment services of third-party companies on our website. When you make a
            purchase from us, your payment data (e.g. name, payment amount, bank account details,
            credit card number) are processed by the payment service provider for the purpose of
            payment processing. For these transactions, the respective contractual and data
            protection provisions of the respective providers apply. The use of the payment service
            providers is based on Art. 6(1)(b) GDPR (contract processing) and in the interest of a
            smooth, convenient, and secure payment transaction (Art. 6(1)(f) GDPR). Insofar as your
            consent is requested for certain actions, Art. 6(1)(a) GDPR is the legal basis for data
            processing; consent may be revoked at any time for the future.
          </p>
          <p>
            We use the following payment services / payment service providers within the scope of
            this website:
          </p>
          <h4>PayPal</h4>{' '}
          <p>
            The provider of this payment service is PayPal (Europe) S.&agrave;.r.l. et Cie, S.C.A.,
            22-24 Boulevard Royal, L-2449 Luxembourg (hereinafter &ldquo;PayPal&rdquo;).
          </p>{' '}
          <p>
            Data transmission to the US is based on the Standard Contractual Clauses (SCC) of the
            European Commission. Details can be found here:{' '}
            <a
              href="https://www.paypal.com/de/webapps/mpp/ua/pocpsa-full"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.paypal.com/de/webapps/mpp/ua/pocpsa-full
            </a>
            .
          </p>{' '}
          <p>
            Details can be found in PayPal&rsquo;s privacy policy:{' '}
            <a
              href="https://www.paypal.com/de/webapps/mpp/ua/privacy-full"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.paypal.com/de/webapps/mpp/ua/privacy-full
            </a>
            .
          </p>
          <h4>Stripe</h4>{' '}
          <p>
            The provider for customers within the EU is Stripe Payments Europe, Ltd,1 Grand Canal
            Street Lower, Grand Canal Dock, Dublin, Ireland (hereinafter &ldquo;Stripe&rdquo;).
          </p>{' '}
          <p>
            Data transmission to the US is based on the Standard Contractual Clauses (SCC) of the
            European Commission. Details can be found here:{' '}
            <a href="https://stripe.com/de/privacy" target="_blank" rel="noopener noreferrer">
              https://stripe.com/de/privacy
            </a>{' '}
            and{' '}
            <a
              href="https://stripe.com/de/guides/general-data-protection-regulation"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://stripe.com/de/guides/general-data-protection-regulation
            </a>
            .
          </p>{' '}
          <p>
            Details can be found in Stripe&rsquo;s Privacy Policy at the following link:{' '}
            <a href="https://stripe.com/de/privacy" target="_blank" rel="noopener noreferrer">
              https://stripe.com/de/privacy
            </a>
            .
          </p>
          <h2>7. Online-based Audio and Video Conferences (Conference tools)</h2>
          <h4>Data processing</h4>{' '}
          <p>
            We use online conference tools, among other things, for communication with our
            customers. The tools we use are listed in detail below. If you communicate with us by
            video or audio conference using the Internet, your personal data will be collected and
            processed by the provider of the respective conference tool and by us. The conferencing
            tools collect all information that you provide/access to use the tools (email address
            and/or your phone number). Furthermore, the conference tools process the duration of the
            conference, start and end (time) of participation in the conference, number of
            participants and other &ldquo;context information&rdquo; related to the communication
            process (metadata).
          </p>{' '}
          <p>
            Furthermore, the provider of the tool processes all the technical data required for the
            processing of the online communication. This includes, in particular, IP addresses, MAC
            addresses, device IDs, device type, operating system type and version, client version,
            camera type, microphone or loudspeaker and the type of connection.
          </p>{' '}
          <p>
            Should content be exchanged, uploaded, or otherwise made available within the tool, it
            is also stored on the servers of the tool provider. Such content includes, but is not
            limited to, cloud recordings, chat/ instant messages, voicemail uploaded photos and
            videos, files, whiteboards, and other information shared while using the service.
          </p>{' '}
          <p>
            Please note that we do not have complete influence on the data processing procedures of
            the tools used. Our possibilities are largely determined by the corporate policy of the
            respective provider. Further information on data processing by the conference tools can
            be found in the data protection declarations of the tools used, and which we have listed
            below this text.
          </p>{' '}
          <h4>Purpose and legal bases</h4>{' '}
          <p>
            The conference tools are used to communicate with prospective or existing contractual
            partners or to offer certain services to our customers (Art. 6(1)(b) GDPR). Furthermore,
            the use of the tools serves to generally simplify and accelerate communication with us
            or our company (legitimate interest in the meaning of Art. 6(1)(f) GDPR). Insofar as
            consent has been requested, the tools in question will be used on the basis of this
            consent; the consent may be revoked at any time with effect from that date.
          </p>{' '}
          <h4>Duration of storage</h4>{' '}
          <p>
            Data collected directly by us via the video and conference tools will be deleted from
            our systems immediately after you request us to delete it, revoke your consent to
            storage, or the reason for storing the data no longer applies. Stored cookies remain on
            your end device until you delete them. Mandatory legal retention periods remain
            unaffected.
          </p>{' '}
          <p>
            We have no influence on the duration of storage of your data that is stored by the
            operators of the conference tools for their own purposes. For details, please directly
            contact the operators of the conference tools.
          </p>{' '}
          <h4>Conference tools used</h4> <p>We employ the following conference tools:</p>
          <h3>Microsoft Teams</h3>{' '}
          <p>
            We use Microsoft Teams. The provider is the Microsoft Ireland Operations Limited, One
            Microsoft Place, South County Business Park, Leopardstown, Dublin 18, Ireland. For
            details on data processing, please refer to the Microsoft Teams privacy policy:{' '}
            <a
              href="https://privacy.microsoft.com/en-us/privacystatement"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://privacy.microsoft.com/en-us/privacystatement
            </a>
            .
          </p>
          <p>
            The company is certified in accordance with the &ldquo;EU-US Data Privacy
            Framework&rdquo; (DPF). The DPF is an agreement between the European Union and the US,
            which is intended to ensure compliance with European data protection standards for data
            processing in the US. Every company certified under the DPF is obliged to comply with
            these data protection standards. For more information, please contact the provider under
            the following link:{' '}
            <a
              href="https://www.dataprivacyframework.gov/s/participant-search/participant-detail?contact=true&id=a2zt0000000KzNaAAK&status=Active"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.dataprivacyframework.gov/s/participant-search/participant-detail?contact=true&id=a2zt0000000KzNaAAK&status=Active
            </a>
          </p>
          <h4>Data processing</h4>{' '}
          <p>
            We have concluded a data processing agreement (DPA) for the use of the above-mentioned
            service. This is a contract mandated by data privacy laws that guarantees that they
            process personal data of our website visitors only based on our instructions and in
            compliance with the GDPR.
          </p>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default Privacy;
