import { FunctionComponent } from 'react';
import './newsletter.scss';
import Footer from '../footer/footer';
import TopMenu from '../top-menu/top-menu';

export type Props = {};

const NewsletterConfirm2: FunctionComponent<Props> = (props) => {
  // ----------------------------------------------------------------
  // ----------------------------------------------------------------
  // ----------------------------------------------------------------
  // ----------------------------------------------------------------
  // ----------------------------------------------------------------
  return (
    <div className="page contact">
      <TopMenu selected="contact"></TopMenu>
      <div className="page-content bg-top">
        <div className="inner-content">
          <h1>Welcome to the SurveyTester Family! 🎉</h1>
        </div>
      </div>
      <div className="page-content bg-1">
        <div className="inner-content">
          <h2>Congratulations! You're in! 🚀</h2>
          <p>
            We're thrilled to have you on board. Now that you've confirmed your subscription, you're
            officially part of the SurveyTester community - a place where innovation meets
            efficiency, and where your testing processes are about to get a serious upgrade.
          </p>
          <h3>What's Next?</h3>
          <p>
            Before your first newsletter lands in your inbox, here are some ways you can start
            exploring what SurveyTester has to offer:
            <ol>
              <li>
                <b>Get to Know Us:</b> Check out our Blog for the latest insights on survey testing,
                industry trends, and best practices.
              </li>
              <li>
                <b>Explore Our Tools:</b> Dive into our Feature Overview and discover how
                SurveyTester can streamline your testing process, reduce errors, and boost your
                productivity.
              </li>
              <li>
                <b>Join the Conversation:</b> Follow us on LinkedIn and Twitter to stay connected
                with a community of like-minded professionals.
              </li>
              <li>
                <b>Watch a Demo:</b> See SurveyTester in action with our Quick Demo Video and learn
                how our tools can make your testing more efficient.
              </li>
            </ol>
          </p>
          {/*

                    <h3>We'd Love to Know More About You! 🤔</h3>
          <p>
            To make sure we're delivering the content that's most relevant to you, we'd appreciate
            it if you could take just a moment to fill out this quick survey. It'll help us tailor
            our newsletters to fit your needs perfectly.
          </p>
          <NewsletterBrevoAdditional />
          <p>
            We know your time is valuable, so thanks in advance for sharing a bit more about
            yourself. It will help us make sure that every email you receive from us is packed with
            the insights and information you need to excel in your work.
          </p>
          */}

          <p>
            <b>Thanks for joining us, and here's to making your testing journey better together!</b>
          </p>
          <p>
            Cheers,
            <br />
            The SurveyTester Team
          </p>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default NewsletterConfirm2;
